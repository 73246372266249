/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { usePostReviewActivityReportSendMutation } from 'store/api'
import { useAuthInfo } from 'store/hooks'
import { isValidString } from 'helpers/isValidString'
import { isAllowed } from 'helpers/isAllowed'

/* Component imports -------------------------------------------------------- */
import {
  Dialog,
  DialogTitle,
  Fab,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
} from '@mui/material'
import {
  Check,
  ChecklistRtl,
  Close,
  EditNote,
  EmojiEvents,
  PostAdd,
  Send,
} from '@mui/icons-material'
import CloseButton from 'components/CloseButton/CloseButton'
import Podium from 'components/Podium/Podium'
import ReviewsReportEditModal from './ReviewsReportEditModal'
import ReviewsReportListModal from './ReviewsReportListModal'

/* Type imports ------------------------------------------------------------- */
import type { Socket } from 'socket.io-client'
import type { Winner } from 'components/Podium/Podium'
import type { ReviewBoard } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const ManageReportButton = styled(SpeedDial)`
  position: absolute;
  right: 90px;
  bottom: 20px;
`

const SendReportButton = styled(Fab)`
  position: absolute;
  right: 20px;
  bottom: 20px;
`

const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  text-transform: uppercase;
  text-align: center;
`

const DialogContentContainer = styled.div`
  margin: 0px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

/* Component declaration ---------------------------------------------------- */
interface ReviewsReportManagementProps {
  board: ReviewBoard;
  socket: Socket;
}

const ReviewsReportManagement: React.FC<ReviewsReportManagementProps> = ({
  board: { userId, activityReports = [], id, displayReports, rates = []},
  socket,
}) => {
  const authInfo = useAuthInfo()
  const [ openEdit, setOpenEdit ] = useState<boolean>(false)
  const [ openList, setOpenList ] = useState<boolean>(false)
  const [ openRanking, setOpenRanking ] = useState<boolean>(false)
  const [ disableButton, setDisabledButton ] = useState<boolean>(false)

  const [
    submitSendReport,
    { isLoading: isSendingReport },
  ] = usePostReviewActivityReportSendMutation()

  useEffect(() => {
    socket.on(`boards:${id}:ranking`, () => setOpenRanking(true))

    return () => {
      socket.off(`boards:${id}:ranking`)
    }
  }, [ id, socket ])

  const onSendClick = async () => {
    setDisabledButton(true)
    !isSendingReport && await submitSendReport({ reviewBoardId: id })
  }

  const onCloseEdit = () => {
    setOpenEdit(false)
    setDisabledButton(false)
  }

  const reportActions = useMemo(() => [
    {
      name: 'Mon avancement',
      icon: <EditNote />,
      action: () => setOpenEdit(true),
    },
    {
      name: 'Liste des comptes rendus',
      icon: <ChecklistRtl />,
      action: () => setOpenList(true),
      disabled: isAllowed({ userId, user: authInfo }) ? false : !displayReports,
    },
    {
      name: 'Classement',
      icon: <EmojiEvents />,
      action: () => setOpenRanking(true),
      disabled: isAllowed({ userId, user: authInfo }) ? false : !displayReports,
    },
  ], [ userId, displayReports ])

  const doesMyReportExists = useMemo(() => activityReports.find((report) => report.userId === authInfo.id), [ activityReports ])
  const hasMyReportBeenSent = useMemo(() => isValidString(activityReports.find((report) => report.userId === authInfo.id)?.sentAt), [ activityReports ])

  const sentReports = useMemo(() => activityReports.filter((report) => report.sentAt), [ activityReports ])
  const notSentReports = useMemo(() => {
    const drafted: string[] = []
    const nothing: string[] = []
    rates.forEach((rate) => {
      const find = activityReports.find((report) => report.userId === rate.userId)
      if (find) {
        if (!find.sentAt) drafted.push(find.user?.firstNameWithInitial || '')
      } else {
        nothing.push(rate.user?.firstNameWithInitial || '')
      }
    })

    const draftedString = drafted.length === 0 ? '' : `Rapports en brouillon : ${drafted.map((e) => e).join(', ')}.`
    const nothingString = nothing.length === 0 ? '' : `Rapports non-écrits : ${nothing.map((e) => e).join(', ')}.`
    return [ draftedString, nothingString ].join('\n\n')
  }, [ activityReports, rates ])

  const podium: Winner[] = useMemo(() => sentReports.filter((report) => report.isValid).map(({ user, sentAt }, index): Winner => ({
    id: user?.id || 0,
    name: user?.firstNameWithInitial || '',
    avatarColor: user?.settings?.avatarColor || '',
    initials: user?.initials || '',
    place: index + 1,
    time: sentAt || '',
  })), [ activityReports ])

  return (
    <React.Fragment>
      <ManageReportButton
        FabProps={
          {
            color: 'secondary',
          }
        }
        ariaLabel="Report Management"
        icon={
          <SpeedDialIcon
            icon={<PostAdd />}
            openIcon={<Close />}
          />
        }
      >
        {
          reportActions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              FabProps={{ disabled: action.disabled }}
              onClick={action.action}
            />
          ))
        }
      </ManageReportButton>
      <SendReportButton
        onClick={onSendClick}
        color="error"
        disabled={!doesMyReportExists || hasMyReportBeenSent || isSendingReport || disableButton}
      >
        {
          hasMyReportBeenSent ?
            <Check /> :
            <Send />
        }
      </SendReportButton>
      {
        openEdit &&
          <ReviewsReportEditModal
            report={doesMyReportExists}
            reviewBoardId={id}
            handleClose={onCloseEdit}
          />
      }
      {
        openList &&
          <ReviewsReportListModal
            reviewBoardId={id}
            handleClose={() => setOpenList(false)}
            isAllowed={isAllowed({ userId, user: authInfo })}
            notSentReports={notSentReports}
            reports={sentReports}
          />
      }
      {
        openRanking &&
          <Dialog
            open
            onClose={() => setOpenRanking(false)}
            maxWidth="lg"
            fullWidth
          >
            <DialogTitleContainer>
              Classement
              <CloseButton handleClose={() => setOpenRanking(false)} />
            </DialogTitleContainer>
            <DialogContentContainer>
              <Podium winners={podium} />
            </DialogContentContainer>
          </Dialog>
      }
    </React.Fragment>
  )
}

export default ReviewsReportManagement
