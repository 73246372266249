/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useDeleteReviewActivityReportMutation,
  usePatchReviewActivityReportValidateMutation,
} from 'store/api'
import DateUtils from 'helpers/DateUtils'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
} from '@mui/material'
import {
  Check,
  Close,
  Delete,
} from '@mui/icons-material'
import CloseButton from 'components/CloseButton/CloseButton'
import CustomIconButton from 'components/IconButtons/CustomIconButton/CustomIconButton'
import ShowMoreLessText from 'components/ShowMoreLessText/ShowMoreLessText'

/* Type imports ------------------------------------------------------------- */
import type { ReviewActivityReport } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  text-transform: uppercase;
  text-align: center;
`

const DialogContentContainer = styled.div`
  margin: 0px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const DraftReports = styled.b`
  color: ${(props) => props.theme.palette.error.main};
  margin-bottom: 20px;
`

const TimeStamp = styled.div`
  display: flex;
  justify-content: space-between;

  .sent-report-title {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  gap: 5px;
`

/* Component declaration ---------------------------------------------------- */
interface ReviewsReportListModalProps {
  reviewBoardId: number;
  reports: ReviewActivityReport[];
  handleClose: () => void;
  isAllowed: boolean;
  notSentReports: string;
}

const ReviewsReportListModal: React.FC<ReviewsReportListModalProps> = ({
  handleClose,
  reports,
  reviewBoardId,
  isAllowed,
  notSentReports,
}) => {

  const [
    submitDeleteReport,
    { isLoading: isDeletingReport },
  ] = useDeleteReviewActivityReportMutation()
  const [
    submitValidateReport,
  ] = usePatchReviewActivityReportValidateMutation()

  const onDeleteReport = async (reportId: number) => {
    await submitDeleteReport({ id: reportId, reviewBoardId })
  }

  const onValidateReportClick = async (reportId: number, isValid: boolean) => {
    await submitValidateReport({ id: reportId, reviewBoardId, data: { isValid }})
  }

  return (
    <Dialog
      open
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitleContainer>
        Liste des comptes rendus
        <CloseButton handleClose={handleClose} />
      </DialogTitleContainer>
      <DialogContentContainer>
        {
          reports.map((report) => (
            <Card key={report.id}>
              <CardContent>
                <TimeStamp>
                  <div className="sent-report-title">
                    {report.isValid ? <Check /> : <Close color="error" />}
                    <div>
                      <b>
                        {report.user?.firstNameWithInitial}
                      </b>
                      {' le '}
                      <b>
                        {DateUtils.APIStrToLocalDateString(report.sentAt)}
                      </b>
                      {' à '}
                      <b>
                        {DateUtils.APIStrToLocalTimeString(report.sentAt, { hour: '2-digit', minute: '2-digit', second: '2-digit', fractionalSecondDigits: 3 })}
                      </b>
                    </div>
                  </div>
                  {
                    isAllowed &&
                      <ButtonContainer>
                        <Button
                          color="error"
                          variant="outlined"
                          onClick={() => onValidateReportClick(report.id, !report.isValid)}
                        >
                          {report.isValid ? 'Disqualifier' : 'Re-valider'}
                        </Button>
                        <CustomIconButton
                          Icon={Delete}
                          variant="contained"
                          color="error"
                          disabled={isDeletingReport}
                          onClick={() => onDeleteReport(report.id)}
                          label="Supprimer le compte-rendu"
                        />
                      </ButtonContainer>
                  }
                </TimeStamp>
                <ShowMoreLessText
                  content={report.text}
                  strLenLimit={400}
                />
              </CardContent>
            </Card>
          ))
        }
        <DraftReports>
          {notSentReports}
        </DraftReports>
      </DialogContentContainer>
    </Dialog>
  )
}

export default ReviewsReportListModal
