/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** Any JSON object not defined as schema */
export type Any = any;

export interface Activity {
  /** @example "null" */
  id: number;
  /** @example "2025-01-03" */
  date: string;
  /** @example 1 */
  userId: string;
  /** @example "null" */
  userInfo?: UserInfo;
  /** @example 8 */
  nbHours: number;
  /** @example "NOMAD9" */
  projectId: string;
  /** @example "null" */
  project?: Project;
  /** @example "null" */
  clientId: string | null;
  /** @example "null" */
  client?: Client;
  /** @example "null" */
  createdAt: string;
  /** @example "Lorem ipsum dolor sit amet" */
  comment: string;
  /** @example "B1" */
  imputationId: string;
  /** @example "null" */
  trueImputationId: number | null;
  /** @example "null" */
  imputation?: Imputation;
}

export interface Client {
  /** @example "null" */
  id: string;
  /** @example "Jean BON" */
  name: string;
  /** @example "null" */
  groupId: number;
  /** @example "null" */
  group?: ClientGroup;
  /** @example "null" */
  address1: string;
  /** @example "null" */
  address2: string;
  /** @example "null" */
  address3: string;
  /** @example "null" */
  zipCode: string;
  /** @example "null" */
  city: string;
  /** @example "null" */
  phone: string;
  /**
   * @format email
   * @example "admin@euroinfo.fr"
   */
  email: string;
  /** @example "null" */
  closingEmail: string;
  /** @example "null" */
  paymentName: string;
  /** @example "null" */
  paymentAccount: string;
  /** @example "null" */
  tva: number | null;
  /** @example "null" */
  paymentAddress1: string;
  /** @example "null" */
  paymentAddress2: string;
  /** @example "null" */
  paymentAddress3: string;
  /** @example "null" */
  paymentZipCode: string;
  /** @example "null" */
  paymentCity: string;
  /** @example "null" */
  contactName1: string;
  /** @example "null" */
  contactDirectPhone1: string;
  /** @example "null" */
  contactPhone1: string;
  /** @example "null" */
  contactEmail1: string;
  /** @example "null" */
  contactName2: string;
  /** @example "null" */
  contactDirectPhone2: string;
  /** @example "null" */
  contactPhone2: string;
  /** @example "null" */
  contactEmail2: string;
  /** @example "Lorem ipsum dolor sit amet" */
  comment: string;
  /** @example "null" */
  technicalComment: string;
  /** @example "null" */
  verifySave: boolean;
  /** @example "null" */
  isGexwin: boolean;
  /** @example "null" */
  onMailingList: boolean;
  projects?: Project[];
}

export interface ClientGroup {
  /** @example "null" */
  id: number;
  /** @example "Jean BON" */
  name: string;
  clients?: Client[];
}

export interface EnvironmentType {
  /** @example "null" */
  id: number;
  /** @example "Jean BON" */
  name: string;
  /**
   * @format date-time
   * @example "2021-03-23T16:13:08.489+01:00"
   */
  createdAt: string;
  /**
   * @format date-time
   * @example "2021-03-23T16:13:08.489+01:00"
   */
  updatedAt: string;
}

export interface EnvironmentUrl {
  /** @example "null" */
  id: number;
  /** @example "null" */
  url: string;
  /**
   * @format password
   * @example "password"
   */
  password: string | null;
  /** @example "null" */
  euroBase: string | null;
  /** @example "null" */
  typeId: number | null;
  /** @example "null" */
  type?: EnvironmentType;
  /** @example "NOMAD9" */
  projectId: string | null;
  /** @example "null" */
  project?: Project;
  /** @example "null" */
  clientId: string | null;
  /** @example "null" */
  client?: Client;
  /**
   * @format date-time
   * @example "2021-03-23T16:13:08.489+01:00"
   */
  createdAt: string;
  /**
   * @format date-time
   * @example "2021-03-23T16:13:08.489+01:00"
   */
  updatedAt: string;
}

export interface Imputation {
  /** @example "null" */
  id: number;
  /** @example "B1" */
  imputationId: string;
  /** @example "NOMAD9" */
  projectId: string;
  /** @example "null" */
  project?: Project;
  /** @example "Jean BON" */
  name: string;
  /** @example 8 */
  nbHours: number;
}

export interface Project {
  /** @example "null" */
  id: string;
  /** @example "Jean BON" */
  name: string;
  /** @example "null" */
  softwareId: number;
  /** @example "null" */
  software?: Software;
  /** @example "null" */
  clientId: string;
  /** @example "null" */
  client?: Client;
  /** @example "null" */
  statusId: number;
  /** @example "null" */
  status?: ProjectStatus;
  /** @example "null" */
  description: string;
  imputations?: Imputation[];
  activities?: Activity[];
}

export interface ProjectImputation {
  /** @example "null" */
  id: string;
  /** @example "Jean BON" */
  name: string;
}

export interface ProjectStatus {
  /** @example "null" */
  id: number;
  /** @example "Jean BON" */
  name: string;
}

export interface Software {
  /** @example "null" */
  id: number;
  /** @example "Jean BON" */
  name: string;
}

export interface User {
  /** @example "null" */
  id: number;
  /**
   * @format email
   * @example "admin@euroinfo.fr"
   */
  email: string;
  /** @example "null" */
  isAdmin: boolean;
  /** @example "null" */
  settings?: UserSettings;
  /** @example "null" */
  fullName: string;
  /** @example "null" */
  firstNameWithInitial: string;
  /** @example "null" */
  initials: string;
  /** @example "null" */
  avatarColor: string;
  /** @example "null" */
  token?: string;
  /**
   * @format date-time
   * @example "2021-03-23T16:13:08.489+01:00"
   */
  createdAt: string;
  /**
   * @format date-time
   * @example "2021-03-23T16:13:08.489+01:00"
   */
  updatedAt: string;
  /** @example "null" */
  userInfo?: UserInfo;
  activities?: Activity[];
}

export interface UserInfo {
  /** @example "null" */
  id: string;
  /** @example "BON" */
  lastName: string;
  /** @example "Jean" */
  firstName: string;
  /** @example "null" */
  fullName: string;
  /** @example "null" */
  firstNameWithInitial: string;
  /** @example "null" */
  initials: string;
  /**
   * @format email
   * @example "admin@euroinfo.fr"
   */
  email: string;
  /** @example false */
  disabled: boolean;
  /** @example "null" */
  user?: User;
  activities?: Activity[];
}

export interface UserSettings {
  /** @example "null" */
  id: number;
  /** @example 1 */
  userId: number;
  /** @example "null" */
  color1: string;
  /** @example "null" */
  color2: string;
  /** @example "null" */
  color3: string;
  /** @example "null" */
  avatarColor: string;
  /** @example "null" */
  monday: number;
  /** @example "null" */
  tuesday: number;
  /** @example "null" */
  wednesday: number;
  /** @example "null" */
  thursday: number;
  /** @example "null" */
  friday: number;
  activityReminderDay?: WeekDay | null;
}

export interface Incident {
  /** @example "null" */
  id: number;
  /** @example "null" */
  clientId: string;
  /** @example "null" */
  client?: Client;
  /** @example "null" */
  priorityId: number;
  /** @example "null" */
  priority?: IncidentPriority;
  /** @example "null" */
  familyId: number;
  /** @example "null" */
  family?: IncidentFamily;
  /** @example "null" */
  falseSubFamilyId: number;
  /** @example "null" */
  subFamilyId: number | null;
  /** @example "null" */
  subFamily?: IncidentSubFamily;
  /** @example "null" */
  createdAt: string;
  /** @example "null" */
  accountedAt: string | null;
  /** @example "null" */
  closedAt: string;
  /** @example "null" */
  reOpenedAt: string | null;
  /** @example "Lorem ipsum dolor sit amet" */
  comment: string;
  /** @example "null" */
  resolvedComment: string;
  /** @example "null" */
  resolvedDuration: number;
  /** @example 1 */
  userId: number;
  /** @example "null" */
  assignedTo?: IncidentAssignment;
  /** @example "null" */
  statusId: string;
  /** @example "null" */
  status?: IncidentStatus;
  /** @example "null" */
  clientRelaunch: boolean;
  /** @example "null" */
  clientRelaunchAmount: number;
  /** @example "null" */
  reLaunchedAt: string | null;
  /** @example "null" */
  ticketUrl: string | null;
  /** @example "null" */
  contact: string | null;
  files?: IncidentFile[];
  history?: IncidentHistory[];
}

export interface IncidentAction {
  /** @example "null" */
  id: string;
  /** @example "Jean BON" */
  name: string;
}

export interface IncidentAssignment {
  /** @example "null" */
  id: number;
  /** @example "Jean BON" */
  name: string;
  /** @example false */
  disabled: boolean;
}

export interface IncidentFamily {
  /** @example "null" */
  id: number;
  /** @example "Jean BON" */
  name: string;
  subFamilies?: IncidentSubFamily[];
  /** @example false */
  disabled: boolean;
}

export interface IncidentFile {
  /** @example "null" */
  id: number;
  /** @example "null" */
  incidentId: number;
  /** @example "null" */
  incident?: Incident;
  /** @example "null" */
  path: string;
  /** @example "null" */
  thumbnailPath: string;
  /** @example "Jean BON" */
  name: string;
  /** @example "null" */
  historyId: number | null;
  /** @example "null" */
  flagEmail: string | null;
}

export interface IncidentHistory {
  /** @example "null" */
  id: number;
  /** @example "null" */
  incidentId: number;
  /** @example "null" */
  incident?: Incident;
  /** @example "null" */
  createdAt: string;
  /** @example 1 */
  userId: string;
  /** @example "null" */
  userInfo?: UserInfo;
  /** @example "null" */
  actionId: string;
  /** @example "null" */
  action?: IncidentAction;
  /** @example "Lorem ipsum dolor sit amet" */
  comment: string | null;
  /** @example "null" */
  actionName: string;
}

export interface IncidentPriority {
  /** @example "null" */
  id: number;
  /** @example "Jean BON" */
  name: string;
}

export interface IncidentStatus {
  /** @example "null" */
  id: string;
  /** @example "Jean BON" */
  name: string;
  /** @example false */
  disabled: boolean;
}

export interface IncidentSubFamily {
  /** @example "null" */
  id: number;
  /** @example "null" */
  subFamilyId: number;
  /** @example "null" */
  familyId: number;
  /** @example "null" */
  family?: IncidentFamily;
  /** @example "Jean BON" */
  name: string;
  /** @example false */
  disabled: boolean;
}

export interface ReviewActivityReport {
  /** @example "null" */
  id: number;
  /** @example "null" */
  text: string;
  /** @example "null" */
  isValid: boolean;
  /**
   * @format date-time
   * @example "2021-03-23T16:13:08.489+01:00"
   */
  sentAt: string | null;
  /** @example 1 */
  userId: number;
  /** @example "null" */
  user?: User;
  /** @example "null" */
  reviewBoardId: number;
  /** @example "null" */
  board?: ReviewBoard;
  /**
   * @format date-time
   * @example "2021-03-23T16:13:08.489+01:00"
   */
  createdAt: string;
  /**
   * @format date-time
   * @example "2021-03-23T16:13:08.489+01:00"
   */
  updatedAt: string;
}

export interface ReviewBoard {
  /** @example "null" */
  id: number;
  /** @example "null" */
  title: string;
  /** @example "null" */
  magicWord: string;
  /** @example "null" */
  displayReports: boolean;
  /**
   * @format date-time
   * @example "2021-03-23T16:13:08.489+01:00"
   */
  startDate: string | null;
  /**
   * @format date-time
   * @example "2021-03-23T16:13:08.489+01:00"
   */
  endDate: string | null;
  /** @example 1 */
  userId: number;
  /** @example "null" */
  user?: User;
  boardCategories?: ReviewBoardCategory[];
  rates?: ReviewRate[];
  activityReports?: ReviewActivityReport[];
  cards?: ReviewCard[];
  /** @example "null" */
  rate: number;
  /**
   * @format date-time
   * @example "2021-03-23T16:13:08.489+01:00"
   */
  createdAt: string;
  /**
   * @format date-time
   * @example "2021-03-23T16:13:08.489+01:00"
   */
  updatedAt: string;
}

export interface ReviewBoardCategory {
  /** @example "null" */
  id: number;
  /** @example "Jean BON" */
  name: string;
  /** @example "null" */
  reviewCategoryId: number | null;
  /** @example "null" */
  category?: ReviewCategory;
  /** @example "null" */
  reviewBoardId: number;
  /** @example "null" */
  board?: ReviewBoard;
  cards?: ReviewCard[];
  /**
   * @format date-time
   * @example "2021-03-23T16:13:08.489+01:00"
   */
  createdAt: string;
  /**
   * @format date-time
   * @example "2021-03-23T16:13:08.489+01:00"
   */
  updatedAt: string;
}

export interface ReviewCard {
  /** @example "null" */
  id: number;
  /** @example "null" */
  title: string;
  /** @example "null" */
  description: string | null;
  /** @example "null" */
  isRead: boolean;
  /** @example "null" */
  index: number;
  /** @example 1 */
  userId: number;
  /** @example "null" */
  user?: User;
  /** @example "null" */
  reviewBoardId: number;
  /** @example "null" */
  board?: ReviewBoard;
  /** @example "null" */
  reviewBoardCategoryId: number;
  /** @example "null" */
  boardCategory?: ReviewBoardCategory;
  reactions?: ReviewCardReaction[];
  comments?: ReviewCardComment[];
  /**
   * @format date-time
   * @example "2021-03-23T16:13:08.489+01:00"
   */
  createdAt: string;
  /**
   * @format date-time
   * @example "2021-03-23T16:13:08.489+01:00"
   */
  updatedAt: string;
}

export interface ReviewCardComment {
  /** @example "null" */
  id: number;
  /** @example "null" */
  message: string;
  /** @example 1 */
  userId: number;
  /** @example "null" */
  user?: User;
  /** @example "null" */
  reviewCardId: number;
  /** @example "null" */
  card?: ReviewCard;
  reactions?: ReviewCardCommentReaction[];
  /**
   * @format date-time
   * @example "2021-03-23T16:13:08.489+01:00"
   */
  createdAt: string;
  /**
   * @format date-time
   * @example "2021-03-23T16:13:08.489+01:00"
   */
  updatedAt: string;
}

export interface ReviewCardCommentReaction {
  /** @example "null" */
  id: number;
  /** @example "null" */
  emoji: string;
  /** @example 1 */
  userId: number;
  /** @example "null" */
  user?: User;
  /** @example "null" */
  reviewCardCommentId: number;
  /** @example "null" */
  cardComment?: ReviewCardComment;
  /**
   * @format date-time
   * @example "2021-03-23T16:13:08.489+01:00"
   */
  createdAt: string;
  /**
   * @format date-time
   * @example "2021-03-23T16:13:08.489+01:00"
   */
  updatedAt: string;
}

export interface ReviewCardReaction {
  /** @example "null" */
  id: number;
  /** @example "null" */
  emoji: string;
  /** @example 1 */
  userId: number;
  /** @example "null" */
  user?: User;
  /** @example "null" */
  reviewCardId: number | null;
  /** @example "null" */
  card?: ReviewCard;
  /**
   * @format date-time
   * @example "2021-03-23T16:13:08.489+01:00"
   */
  createdAt: string;
  /**
   * @format date-time
   * @example "2021-03-23T16:13:08.489+01:00"
   */
  updatedAt: string;
}

export interface ReviewCategory {
  /** @example "null" */
  id: number;
  /** @example "Jean BON" */
  name: string;
  boardCategories?: ReviewBoardCategory[];
  /**
   * @format date-time
   * @example "2021-03-23T16:13:08.489+01:00"
   */
  createdAt: string;
  /**
   * @format date-time
   * @example "2021-03-23T16:13:08.489+01:00"
   */
  updatedAt: string;
}

export interface ReviewRate {
  /** @example "null" */
  id: number;
  /** @example "null" */
  rate: number;
  /** @example 1 */
  userId: number;
  /** @example "null" */
  user?: User;
  /** @example "null" */
  reviewBoardId: number;
  /** @example "null" */
  board?: ReviewBoard;
  /**
   * @format date-time
   * @example "2021-03-23T16:13:08.489+01:00"
   */
  createdAt: string;
  /**
   * @format date-time
   * @example "2021-03-23T16:13:08.489+01:00"
   */
  updatedAt: string;
}

export enum WeekDay {
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
}

export interface ActivityStoreBulkData {
  /** @example 0 */
  id: number;
  /** @example "2025-01-03" */
  date: string;
  /** @example 8 */
  nbHours: number;
  /** @example "NOMAD9" */
  projectId: string;
  /** @example "B1" */
  imputationId: string;
  /** @example "null" */
  clientId?: any;
  /** @example "Lorem ipsum dolor sit amet" */
  comment: string;
  /** @example false */
  delete?: boolean;
}

export interface ActivityStoreBulk {
  /** @example 1 */
  userId?: number;
  activities: ActivityStoreBulkData[];
}

export interface Pagination {
  /** @example 0 */
  total: number;
  /** @example 0 */
  perPage: number;
  /** @example 0 */
  currentPage: number;
  /** @example 0 */
  lastPage: number;
  /** @example 0 */
  firstPage: number;
}

export interface ActivityPaginated {
  /** @example "null" */
  meta: Pagination;
  data: Activity[];
}

export interface ClientPaginated {
  /** @example "null" */
  meta: Pagination;
  data: Client[];
}

export interface EnvironmentUrlPaginated {
  /** @example "null" */
  meta: Pagination;
  data: EnvironmentUrl[];
}

export interface IncidentPaginated {
  /** @example "null" */
  meta: Pagination;
  data: Incident[];
}

export interface ProjectPaginated {
  /** @example "null" */
  meta: Pagination;
  data: Project[];
}

export interface UserPaginated {
  /** @example "null" */
  meta: Pagination;
  data: User[];
}

export interface UserInfoPaginated {
  /** @example "null" */
  meta: Pagination;
  data: UserInfo[];
}

export interface ReviewBoardEmail {
  /** @example "null" */
  title: string;
  /** @example "null" */
  message: string;
}

export interface ReviewBoardTest {
  /** @example "null" */
  board: string;
}

export interface ActivityStoreValidator {
  /** @example "2025-01-03" */
  date: string;
  /** @example 8 */
  nbHours: number;
  /** @example "NOMAD9" */
  projectId: string;
  /** @example "B1" */
  imputationId: string;
  /** @example "null" */
  clientId?: string | null;
  /** @example "Lorem ipsum dolor sit amet" */
  comment: string;
}

export interface ActivityStoreBulkValidator {
  /** @example "null" */
  activities: {
    /** @example 0 */
    id: number;
    /** @example "2025-01-03" */
    date: string;
    /** @example 8 */
    nbHours: number;
    /** @example "NOMAD9" */
    projectId: string;
    /** @example "B1" */
    imputationId: string;
    /** @example "null" */
    clientId?: string | null;
    /** @example "Lorem ipsum dolor sit amet" */
    comment: string;
    /** @example false */
    delete?: boolean;
  }[];
}

export interface ActivityUpdateValidator {
  /** @example "2025-01-03" */
  date?: string;
  /** @example 8 */
  nbHours?: number;
  /** @example "NOMAD9" */
  projectId?: string;
  /** @example "B1" */
  imputationId?: string;
  /** @example "null" */
  clientId?: string | null;
  /** @example "Lorem ipsum dolor sit amet" */
  comment?: string;
}

export interface ClientStoreValidator {
  /** @example "null" */
  id: string;
  /** @example "Jean BON" */
  name: string;
  /** @example 0 */
  groupId: number;
  /** @example "null" */
  address1?: string;
  /** @example "null" */
  address2?: string;
  /** @example "null" */
  address3?: string;
  /** @example "null" */
  zipCode?: string;
  /** @example "null" */
  city?: string;
  /** @example "null" */
  phone?: string;
  /** @example "admin@euroinfo.fr" */
  email?: string;
  /** @example "null" */
  closingEmail?: string;
  /** @example "null" */
  paymentName?: string;
  /** @example 0 */
  tva?: number | null;
  /** @example "null" */
  paymentAddress1?: string;
  /** @example "null" */
  paymentAddress2?: string;
  /** @example "null" */
  paymentAddress3?: string;
  /** @example "null" */
  paymentZipCode?: string;
  /** @example "null" */
  paymentCity?: string;
  /** @example "null" */
  contactName1?: string;
  /** @example "null" */
  contactDirectPhone1?: string;
  /** @example "null" */
  contactEmail1?: string;
  /** @example "null" */
  contactName2?: string;
  /** @example "null" */
  contactDirectPhone2?: string;
  /** @example "null" */
  contactEmail2?: string;
  /** @example "Lorem ipsum dolor sit amet" */
  comment?: string;
  /** @example "null" */
  technicalComment?: string;
}

export interface ClientUpdateValidator {
  /** @example "Jean BON" */
  name?: string;
  /** @example 0 */
  groupId?: number;
  /** @example "null" */
  address1?: string;
  /** @example "null" */
  address2?: string;
  /** @example "null" */
  address3?: string;
  /** @example "null" */
  zipCode?: string;
  /** @example "null" */
  city?: string;
  /** @example "null" */
  phone?: string;
  /** @example "admin@euroinfo.fr" */
  email?: string;
  /** @example "null" */
  closingEmail?: string;
  /** @example "null" */
  paymentName?: string;
  /** @example 0 */
  tva?: number;
  /** @example "null" */
  paymentAddress1?: string;
  /** @example "null" */
  paymentAddress2?: string;
  /** @example "null" */
  paymentAddress3?: string;
  /** @example "null" */
  paymentZipCode?: string;
  /** @example "null" */
  paymentCity?: string;
  /** @example "null" */
  contactName1?: string;
  /** @example "null" */
  contactDirectPhone1?: string;
  /** @example "null" */
  contactEmail1?: string;
  /** @example "null" */
  contactName2?: string;
  /** @example "null" */
  contactDirectPhone2?: string;
  /** @example "null" */
  contactEmail2?: string;
  /** @example "Lorem ipsum dolor sit amet" */
  comment?: string;
  /** @example "null" */
  technicalComment?: string;
}

export interface EnvironmentTypeStoreValidator {
  /** @example "Jean BON" */
  name: string;
}

export interface EnvironmentTypeUpdateValidator {
  /** @example "Jean BON" */
  name?: string;
}

export interface EnvironmentUrlStoreValidator {
  /** @example "null" */
  url: string;
  /** @example 0 */
  typeId?: number | null;
  /** @example "NOMAD9" */
  projectId?: string | null;
  /** @example "null" */
  clientId?: string | null;
  /** @example "password" */
  password?: string | null;
  /** @example "null" */
  euroBase?: string | null;
}

export interface EnvironmentUrlUpdateValidator {
  /** @example "null" */
  url?: string;
  /** @example 0 */
  typeId?: number | null;
  /** @example "NOMAD9" */
  projectId?: string | null;
  /** @example "null" */
  clientId?: string | null;
  /** @example "password" */
  password?: string | null;
  /** @example "null" */
  euroBase?: string | null;
}

export interface ImputationStoreValidator {
  /** @example 8 */
  nbHours: number;
  /** @example "NOMAD9" */
  projectId: string;
  /** @example "B1" */
  imputationId: string;
  /** @example "Jean BON" */
  name: string;
}

export interface ImputationStoreDefaultValidator {
  /** @example "NOMAD9" */
  projectId: string;
}

export interface ImputationUpdateValidator {
  /** @example 8 */
  nbHours?: number;
  /** @example "NOMAD9" */
  projectId?: string;
  /** @example "B1" */
  imputationId?: string;
  /** @example "Jean BON" */
  name?: string;
}

export interface LoginValidator {
  /** @example "admin@euroinfo.fr" */
  email: string;
  /** @example "password" */
  password: string;
}

export interface ProjectStoreValidator {
  /** @example "null" */
  id: string;
  /** @example "Jean BON" */
  name: string;
  /** @example 0 */
  softwareId: number;
  /** @example "null" */
  clientId?: string;
  /** @example 0 */
  statusId: number;
  /** @example "null" */
  description?: string;
}

export interface ProjectUpdateValidator {
  /** @example "Jean BON" */
  name?: string;
  /** @example 0 */
  softwareId?: number;
  /** @example "null" */
  clientId?: string;
  /** @example 0 */
  statusId?: number;
  /** @example "null" */
  description?: string;
  /** @example "null" */
  imputations?: {
    /** @example 0 */
    id: number;
    /** @example "B1" */
    imputationId: string;
    /** @example "NOMAD9" */
    projectId: string;
    /** @example "Jean BON" */
    name: string;
    /** @example 8 */
    nbHours: number;
  }[];
}

export interface UserInfoStoreValidator {
  /** @example "null" */
  id: string;
  /** @example "BON" */
  lastName: string;
  /** @example "Jean" */
  firstName: string;
  /** @example "admin@euroinfo.fr" */
  email: string;
}

export interface UserInfoUpdateValidator {
  /** @example "BON" */
  lastName?: string;
  /** @example "Jean" */
  firstName?: string;
  /** @example "admin@euroinfo.fr" */
  email?: string;
}

export interface UserSettingsStoreValidator {
  /** @example "null" */
  color1: string;
  /** @example "null" */
  color2: string;
  /** @example "null" */
  color3: string;
  /** @example "null" */
  avatarColor: string;
  /** @example 0 */
  monday: number;
  /** @example 0 */
  tuesday: number;
  /** @example 0 */
  wednesday: number;
  /** @example 0 */
  thursday: number;
  /** @example 0 */
  friday: number;
  activityReminderDay?: WeekDay;
}

export interface UserSettingsUpdateValidator {
  /** @example "null" */
  color1?: string;
  /** @example "null" */
  color2?: string;
  /** @example "null" */
  color3?: string;
  /** @example "null" */
  avatarColor: string;
  /** @example 0 */
  monday?: number;
  /** @example 0 */
  tuesday?: number;
  /** @example 0 */
  wednesday?: number;
  /** @example 0 */
  thursday?: number;
  /** @example 0 */
  friday?: number;
  activityReminderDay?: WeekDay | null;
}

export interface UserStoreValidator {
  /** @example "admin@euroinfo.fr" */
  email: string;
  /** @example "password" */
  password: string;
}

export interface UserUpdateValidator {
  /** @example "admin@euroinfo.fr" */
  email?: string;
  /** @example "password" */
  password?: string;
  /** @example false */
  disabled?: boolean;
  /** @example false */
  isAdmin?: boolean;
}

export interface UserUpdatePasswordValidator {
  /** @example "null" */
  newPassword: string;
  /** @example "null" */
  oldPassword: string;
}

export interface IncidentFileStoreValidator {
  /** @example "null" */
  files: File[];
}

export interface IncidentFileUpdateValidator {
  /** @example "null" */
  files: File[];
}

export interface IncidentHistoryStoreValidator {
  /** @example "null" */
  actionId: string;
  /** @example "Lorem ipsum dolor sit amet" */
  comment?: string;
}

export interface IncidentHistoryUpdateValidator {
  /** @example "null" */
  actionId?: string;
  /** @example "Lorem ipsum dolor sit amet" */
  comment?: string;
}

export interface IncidentStoreValidator {
  /** @example "null" */
  clientId: string;
  /** @example 0 */
  priorityId: number;
  /** @example 0 */
  familyId: number;
  /** @example 0 */
  subFamilyId: number;
  /** @example "null" */
  accountedAt?: string;
  /** @example "Lorem ipsum dolor sit amet" */
  comment?: string;
  /** @example "null" */
  resolvedComment?: string;
  /** @example 1 */
  userId: number;
  /** @example "null" */
  statusId: string;
  /** @example false */
  clientRelaunch: boolean;
  /** @example "null" */
  ticketUrl?: string;
  /** @example "null" */
  contact: string;
}

export interface IncidentUpdateValidator {
  /** @example "null" */
  clientId?: string;
  /** @example 0 */
  priorityId?: number;
  /** @example 0 */
  familyId?: number;
  /** @example 0 */
  subFamilyId?: number;
  /** @example "null" */
  accountedAt?: string;
  /** @example "Lorem ipsum dolor sit amet" */
  comment?: string;
  /** @example "null" */
  resolvedComment?: string;
  /** @example 1 */
  userId?: number;
  /** @example "null" */
  statusId?: string;
  /** @example false */
  clientRelaunch?: boolean;
  /** @example "null" */
  ticketUrl?: string;
  /** @example "null" */
  contact?: string;
}

export interface ReviewActivityReportStoreValidator {
  /** @example "null" */
  text: string;
}

export interface ReviewActivityReportUpdateValidator {
  /** @example "null" */
  text: string;
}

export interface ReviewActivityReportUpdateValidValidator {
  /** @example false */
  isValid: boolean;
}

export interface ReviewBoardCategoryStoreValidator {
  /** @example "Jean BON" */
  name: string;
}

export interface ReviewBoardCategoryUpdateValidator {
  /** @example "Jean BON" */
  name: string;
}

export interface ReviewBoardStoreValidator {
  /** @example "null" */
  title?: string;
  /** @example "null" */
  startDate?: string | null;
  /** @example "null" */
  endDate?: string | null;
}

export interface ReviewBoardUpdateValidator {
  /** @example "null" */
  title?: string;
  /** @example "null" */
  startDate?: string | null;
  /** @example "null" */
  endDate?: string | null;
  /** @example false */
  displayReports?: boolean;
  /** @example 1 */
  userId?: number;
  /** @example "null" */
  magicWord?: string;
}

export interface ReviewBoardUpdateCardOrderValidator {
  /** @example "null" */
  cards: {
    /** @example 0 */
    id: number;
    /** @example 0 */
    index: number;
    /** @example 0 */
    reviewBoardCategoryId: number;
    /** @example false */
    delete?: boolean;
  }[];
}

export interface ReviewCardCommentStoreValidator {
  /** @example "null" */
  message: string;
}

export interface ReviewCardCommentUpdateValidator {
  /** @example "null" */
  message: string;
}

export interface ReviewCardStoreValidator {
  /** @example "null" */
  title: string;
  /** @example "null" */
  description?: string | null;
  /** @example false */
  isRead?: boolean;
  /** @example 0 */
  reviewBoardCategoryId: number;
}

export interface ReviewCardUpdateValidator {
  /** @example "null" */
  title?: string;
  /** @example "null" */
  description?: string | null;
  /** @example false */
  isRead?: boolean;
}

export interface ReviewRateStoreValidator {
  /** @example 0 */
  rate: number;
}

export interface ReviewRateUpdateValidator {
  /** @example 0 */
  rate: number;
}

export interface ReviewReactionStoreValidator {
  /** @example "null" */
  emoji: string;
}

export interface ReviewReactionUpdateValidator {
  /** @example "null" */
  emoji?: string;
}

export interface ActivityListParams {
  /** @example "2023-07-01" */
  startDate?: string;
  /** @example "2023-08-01" */
  endDate?: string;
  /** @example "" */
  userIds?: number[];
  /** @example "" */
  clientIds?: string[];
  /** @example "" */
  projectIds?: string[];
  /** @example "date" */
  orderBy?: string;
  /** @example "desc" */
  order?: string;
  /** @example "1" */
  page?: string;
  /** @example "10" */
  limit?: string;
}

export interface IndexPaginatedClientListParams {
  /** @example "" */
  groupIds?: number[];
  /** @example "string" */
  clientId?: string;
  /** @example "string" */
  name?: string;
  /** @example "statusId" */
  orderBy?: string;
  /** @example "asc" */
  order?: string;
  /** @example "1" */
  page?: string;
  /** @example "10" */
  limit?: string;
}

export interface IndexPaginatedEnvironmentUrlListParams {
  /** @example "" */
  typeIds?: number[];
  /** @example "" */
  clientIds?: string[];
  /** @example "" */
  projectIds?: string[];
  /** @example "projectId" */
  orderBy?: string;
  /** @example "asc" */
  order?: string;
  /** @example "1" */
  page?: string;
  /** @example "10" */
  limit?: string;
}

export interface IncidentListParams {
  /** @example "2023-07-01" */
  startDate?: string;
  /** @example "2023-08-01" */
  endDate?: string;
  /** @example "" */
  userIds?: number[];
  /** @example "" */
  clientIds?: string[];
  /** @example "" */
  familyIds?: number[];
  /** @example "" */
  subFamilyIds?: number[];
  /** @example "" */
  statusIds?: string[];
  /** @example "date" */
  orderBy?: string;
  /** @example "desc" */
  order?: string;
  /** @example "1" */
  page?: string;
  /** @example "10" */
  limit?: string;
}

export interface IndexPaginatedProjectListParams {
  /** @example "" */
  softwareIds?: number[];
  /** @example "" */
  clientIds?: string[];
  /** @example "" */
  statusIds?: number[];
  /** @example "string" */
  projectId?: string;
  /** @example "statusId" */
  orderBy?: string;
  /** @example "asc" */
  order?: string;
  /** @example "1" */
  page?: string;
  /** @example "10" */
  limit?: string;
}

export interface IndexPaginatedUserListParams {
  /** @example "" */
  disabled?: boolean;
  /** @example "string" */
  search?: string;
  /** @example "email" */
  orderBy?: string;
  /** @example "asc" */
  order?: string;
  /** @example "1" */
  page?: string;
  /** @example "10" */
  limit?: string;
}

export interface IndexPaginatedUserInfoListParams {
  /** @example "" */
  disabled?: boolean;
  /** @example "string" */
  search?: string;
  /** @example "id" */
  orderBy?: string;
  /** @example "asc" */
  order?: string;
  /** @example "1" */
  page?: string;
  /** @example "10" */
  limit?: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) => {
      const formData = new FormData();

      const typeIsArray = (array: any[], key: string) => {
        array.forEach((value: any, index: number) => {
          if (typeof value === "object") {
            Object.keys(value).forEach((k) => formData.append(`${key}[${index}].${k}`, value[k]));
          } else {
            formData.append(`${key}[${index}]`, value);
          }
        });
      };

      // Only if array was tested. Maybe the other paths need some fixes.
      Object.keys(input || {}).forEach((data) => {
        if (Array.isArray(input[data])) {
          typeIsArray(input[data], data);
        } else {
          if (typeof data === "object") {
            Object.keys(data).forEach((key, index) => formData.append(`${data}[${index}].${key}`, data[key]));
          } else {
            formData.append(`${data}`, data);
          }
        }
      });
      return formData;
    },
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Unlimited Backend
 * @version 1.0.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  activity = {
    /**
     * No description
     *
     * @tags Activity
     * @name StoreBulkActivityCreate
     * @summary  (ActivityController.storeBulk)
     * @request POST:/activity/storeBulk
     * @secure
     */
    storeBulkActivityCreate: (data: ActivityStoreBulk, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/activity/storeBulk`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Returns a ActivityPaginated without any relations.
     *
     * @tags Activity
     * @name ActivityList
     * @summary Returns a ActivityPaginated (ActivityController.index)
     * @request GET:/activity
     * @secure
     */
    activityList: (query: ActivityListParams, params: RequestParams = {}) =>
      this.request<ActivityPaginated, void>({
        path: `/activity`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a Activity without any relations.
     *
     * @tags Activity
     * @name ActivityCreate
     * @summary Returns a Activity (ActivityController.store)
     * @request POST:/activity
     * @secure
     */
    activityCreate: (data: ActivityStoreValidator, params: RequestParams = {}) =>
      this.request<Activity, void>({
        path: `/activity`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a Activity without any relations.
     *
     * @tags Activity
     * @name ActivityDetail
     * @summary Returns a Activity (ActivityController.show)
     * @request GET:/activity/{id}
     * @secure
     */
    activityDetail: (id: number, params: RequestParams = {}) =>
      this.request<Activity, void>({
        path: `/activity/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Activity
     * @name ActivityPartialUpdate
     * @summary Updates a Activity (ActivityController.update)
     * @request PATCH:/activity/{id}
     * @secure
     */
    activityPartialUpdate: (id: number, data: ActivityUpdateValidator, params: RequestParams = {}) =>
      this.request<Activity, void>({
        path: `/activity/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Activity
     * @name ActivityDelete
     * @summary Deletes a Activity (ActivityController.destroy)
     * @request DELETE:/activity/{id}
     * @secure
     */
    activityDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/activity/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  login = {
    /**
     * No description
     *
     * @tags Auth
     * @name LoginCreate
     * @summary Authenticate and get auth token (AuthController.login)
     * @request POST:/login
     */
    loginCreate: (data: LoginValidator, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/login`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  client = {
    /**
     * @description Returns a ClientPaginated without any relations.
     *
     * @tags Client
     * @name IndexPaginatedClientList
     * @summary Returns a ClientPaginated (ClientController.indexPaginated)
     * @request GET:/client/indexPaginated
     * @secure
     */
    indexPaginatedClientList: (query: IndexPaginatedClientListParams, params: RequestParams = {}) =>
      this.request<ClientPaginated, void>({
        path: `/client/indexPaginated`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns an array of Client without any relations.
     *
     * @tags Client
     * @name ClientList
     * @summary Returns an array of Client (ClientController.index)
     * @request GET:/client
     * @secure
     */
    clientList: (params: RequestParams = {}) =>
      this.request<Client[], void>({
        path: `/client`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a Client without any relations.
     *
     * @tags Client
     * @name ClientCreate
     * @summary Returns a Client (ClientController.store)
     * @request POST:/client
     * @secure
     */
    clientCreate: (data: ClientStoreValidator, params: RequestParams = {}) =>
      this.request<Client, void>({
        path: `/client`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a Client without any relations.
     *
     * @tags Client
     * @name ClientDetail
     * @summary Returns a Client (ClientController.show)
     * @request GET:/client/{id}
     * @secure
     */
    clientDetail: (id: string, params: RequestParams = {}) =>
      this.request<Client, void>({
        path: `/client/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Client
     * @name ClientPartialUpdate
     * @summary Updates a Client (ClientController.update)
     * @request PATCH:/client/{id}
     * @secure
     */
    clientPartialUpdate: (id: string, data: ClientUpdateValidator, params: RequestParams = {}) =>
      this.request<Client, void>({
        path: `/client/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Client
     * @name ClientDelete
     * @summary Deletes a Client (ClientController.destroy)
     * @request DELETE:/client/{id}
     * @secure
     */
    clientDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/client/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  clientGroup = {
    /**
     * @description Returns an array of ClientGroup without any relations.
     *
     * @tags ClientGroup
     * @name ClientGroupList
     * @summary Returns an array of ClientGroup (ClientGroupController.index)
     * @request GET:/clientGroup
     * @secure
     */
    clientGroupList: (params: RequestParams = {}) =>
      this.request<ClientGroup[], void>({
        path: `/clientGroup`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a ClientGroup without any relations.
     *
     * @tags ClientGroup
     * @name ClientGroupCreate
     * @summary Returns a ClientGroup (ClientGroupController.store)
     * @request POST:/clientGroup
     * @secure
     */
    clientGroupCreate: (data: any, params: RequestParams = {}) =>
      this.request<ClientGroup, void>({
        path: `/clientGroup`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a ClientGroup without any relations.
     *
     * @tags ClientGroup
     * @name ClientGroupDetail
     * @summary Returns a ClientGroup (ClientGroupController.show)
     * @request GET:/clientGroup/{id}
     * @secure
     */
    clientGroupDetail: (id: number, params: RequestParams = {}) =>
      this.request<ClientGroup, void>({
        path: `/clientGroup/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientGroup
     * @name ClientGroupPartialUpdate
     * @summary Updates a ClientGroup (ClientGroupController.update)
     * @request PATCH:/clientGroup/{id}
     * @secure
     */
    clientGroupPartialUpdate: (id: number, data: any, params: RequestParams = {}) =>
      this.request<ClientGroup, void>({
        path: `/clientGroup/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientGroup
     * @name ClientGroupDelete
     * @summary Deletes a ClientGroup (ClientGroupController.destroy)
     * @request DELETE:/clientGroup/{id}
     * @secure
     */
    clientGroupDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/clientGroup/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  environmentUrl = {
    /**
     * @description Returns a EnvironmentUrlPaginated without any relations.
     *
     * @tags EnvironmentUrl
     * @name IndexPaginatedEnvironmentUrlList
     * @summary Returns a EnvironmentUrlPaginated (EnvironmentUrlController.indexPaginated)
     * @request GET:/environmentUrl/indexPaginated
     * @secure
     */
    indexPaginatedEnvironmentUrlList: (query: IndexPaginatedEnvironmentUrlListParams, params: RequestParams = {}) =>
      this.request<EnvironmentUrlPaginated, void>({
        path: `/environmentUrl/indexPaginated`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns an array of EnvironmentUrl without any relations.
     *
     * @tags EnvironmentUrl
     * @name EnvironmentUrlList
     * @summary Returns an array of EnvironmentUrl (EnvironmentUrlController.index)
     * @request GET:/environmentUrl
     * @secure
     */
    environmentUrlList: (params: RequestParams = {}) =>
      this.request<EnvironmentUrl[], void>({
        path: `/environmentUrl`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a EnvironmentUrl without any relations.
     *
     * @tags EnvironmentUrl
     * @name EnvironmentUrlCreate
     * @summary Returns a EnvironmentUrl (EnvironmentUrlController.store)
     * @request POST:/environmentUrl
     * @secure
     */
    environmentUrlCreate: (data: EnvironmentUrlStoreValidator, params: RequestParams = {}) =>
      this.request<EnvironmentUrl, void>({
        path: `/environmentUrl`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a EnvironmentUrl without any relations.
     *
     * @tags EnvironmentUrl
     * @name EnvironmentUrlDetail
     * @summary Returns a EnvironmentUrl (EnvironmentUrlController.show)
     * @request GET:/environmentUrl/{id}
     * @secure
     */
    environmentUrlDetail: (id: number, params: RequestParams = {}) =>
      this.request<EnvironmentUrl, void>({
        path: `/environmentUrl/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EnvironmentUrl
     * @name EnvironmentUrlPartialUpdate
     * @summary Updates a EnvironmentUrl (EnvironmentUrlController.update)
     * @request PATCH:/environmentUrl/{id}
     * @secure
     */
    environmentUrlPartialUpdate: (id: number, data: EnvironmentUrlUpdateValidator, params: RequestParams = {}) =>
      this.request<EnvironmentUrl, void>({
        path: `/environmentUrl/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EnvironmentUrl
     * @name EnvironmentUrlDelete
     * @summary Deletes a EnvironmentUrl (EnvironmentUrlController.destroy)
     * @request DELETE:/environmentUrl/{id}
     * @secure
     */
    environmentUrlDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/environmentUrl/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  environmentType = {
    /**
     * @description Returns an array of EnvironmentType without any relations.
     *
     * @tags EnvironmentType
     * @name EnvironmentTypeList
     * @summary Returns an array of EnvironmentType (EnvironmentTypeController.index)
     * @request GET:/environmentType
     * @secure
     */
    environmentTypeList: (params: RequestParams = {}) =>
      this.request<EnvironmentType[], void>({
        path: `/environmentType`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a EnvironmentType without any relations.
     *
     * @tags EnvironmentType
     * @name EnvironmentTypeCreate
     * @summary Returns a EnvironmentType (EnvironmentTypeController.store)
     * @request POST:/environmentType
     * @secure
     */
    environmentTypeCreate: (data: EnvironmentTypeStoreValidator, params: RequestParams = {}) =>
      this.request<EnvironmentType, void>({
        path: `/environmentType`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a EnvironmentType without any relations.
     *
     * @tags EnvironmentType
     * @name EnvironmentTypeDetail
     * @summary Returns a EnvironmentType (EnvironmentTypeController.show)
     * @request GET:/environmentType/{id}
     * @secure
     */
    environmentTypeDetail: (id: number, params: RequestParams = {}) =>
      this.request<EnvironmentType, void>({
        path: `/environmentType/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EnvironmentType
     * @name EnvironmentTypePartialUpdate
     * @summary Updates a EnvironmentType (EnvironmentTypeController.update)
     * @request PATCH:/environmentType/{id}
     * @secure
     */
    environmentTypePartialUpdate: (id: number, data: EnvironmentTypeUpdateValidator, params: RequestParams = {}) =>
      this.request<EnvironmentType, void>({
        path: `/environmentType/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags EnvironmentType
     * @name EnvironmentTypeDelete
     * @summary Deletes a EnvironmentType (EnvironmentTypeController.destroy)
     * @request DELETE:/environmentType/{id}
     * @secure
     */
    environmentTypeDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/environmentType/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  imputation = {
    /**
     * @description Created
     *
     * @tags Imputation
     * @name StoreDefaultImputationCreate
     * @summary  (ImputationController.storeDefault)
     * @request POST:/imputation/storeDefault
     * @secure
     */
    storeDefaultImputationCreate: (data: ImputationStoreDefaultValidator, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/imputation/storeDefault`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Returns an array of Imputation without any relations.
     *
     * @tags Imputation
     * @name ImputationList
     * @summary Returns an array of Imputation (ImputationController.index)
     * @request GET:/imputation
     * @secure
     */
    imputationList: (params: RequestParams = {}) =>
      this.request<Imputation[], void>({
        path: `/imputation`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a Imputation without any relations.
     *
     * @tags Imputation
     * @name ImputationCreate
     * @summary Returns a Imputation (ImputationController.store)
     * @request POST:/imputation
     * @secure
     */
    imputationCreate: (data: ImputationStoreValidator, params: RequestParams = {}) =>
      this.request<Imputation, void>({
        path: `/imputation`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a Imputation without any relations.
     *
     * @tags Imputation
     * @name ImputationDetail
     * @summary Returns a Imputation (ImputationController.show)
     * @request GET:/imputation/{id}
     * @secure
     */
    imputationDetail: (id: number, params: RequestParams = {}) =>
      this.request<Imputation, void>({
        path: `/imputation/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Imputation
     * @name ImputationPartialUpdate
     * @summary Updates a Imputation (ImputationController.update)
     * @request PATCH:/imputation/{id}
     * @secure
     */
    imputationPartialUpdate: (id: number, data: ImputationUpdateValidator, params: RequestParams = {}) =>
      this.request<Imputation, void>({
        path: `/imputation/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Imputation
     * @name ImputationDelete
     * @summary Deletes a Imputation (ImputationController.destroy)
     * @request DELETE:/imputation/{id}
     * @secure
     */
    imputationDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/imputation/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  incident = {
    /**
     * @description Returns a IncidentPaginated without any relations.
     *
     * @tags Incident/Incident
     * @name IncidentList
     * @summary Returns a IncidentPaginated (Incident/IncidentController.index)
     * @request GET:/incident
     * @secure
     */
    incidentList: (query: IncidentListParams, params: RequestParams = {}) =>
      this.request<IncidentPaginated, void>({
        path: `/incident`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a Incident without any relations.
     *
     * @tags Incident/Incident
     * @name IncidentCreate
     * @summary Returns a Incident (Incident/IncidentController.store)
     * @request POST:/incident
     * @secure
     */
    incidentCreate: (data: IncidentStoreValidator, params: RequestParams = {}) =>
      this.request<Incident, void>({
        path: `/incident`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a Incident including files, history.
     *
     * @tags Incident/Incident
     * @name IncidentDetail
     * @summary Returns a Incident (Incident/IncidentController.show)
     * @request GET:/incident/{id}
     * @secure
     */
    incidentDetail: (id: number, params: RequestParams = {}) =>
      this.request<Incident, void>({
        path: `/incident/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Incident/Incident
     * @name IncidentPartialUpdate
     * @summary Updates a Incident (Incident/IncidentController.update)
     * @request PATCH:/incident/{id}
     * @secure
     */
    incidentPartialUpdate: (id: number, data: IncidentUpdateValidator, params: RequestParams = {}) =>
      this.request<Incident, void>({
        path: `/incident/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Incident/Incident
     * @name IncidentDelete
     * @summary Deletes a Incident (Incident/IncidentController.destroy)
     * @request DELETE:/incident/{id}
     * @secure
     */
    incidentDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/incident/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description Returns an array of IncidentFile without any relations.
     *
     * @tags Incident/IncidentFile
     * @name FileIncidentList
     * @summary Returns an array of IncidentFile (Incident/IncidentFileController.index)
     * @request GET:/incident/{incidentId}/file
     * @secure
     */
    fileIncidentList: (incidentId: number, params: RequestParams = {}) =>
      this.request<IncidentFile[], void>({
        path: `/incident/${incidentId}/file`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a IncidentFile without any relations.
     *
     * @tags Incident/IncidentFile
     * @name FileIncidentCreate
     * @summary Returns a IncidentFile (Incident/IncidentFileController.store)
     * @request POST:/incident/{incidentId}/file
     * @secure
     */
    fileIncidentCreate: (incidentId: number, data: IncidentFileStoreValidator, params: RequestParams = {}) =>
      this.request<IncidentFile, void>({
        path: `/incident/${incidentId}/file`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a IncidentFile without any relations.
     *
     * @tags Incident/IncidentFile
     * @name FileIncidentDetail
     * @summary Returns a IncidentFile (Incident/IncidentFileController.show)
     * @request GET:/incident/{incidentId}/file/{id}
     * @secure
     */
    fileIncidentDetail: (incidentId: number, id: number, params: RequestParams = {}) =>
      this.request<IncidentFile, void>({
        path: `/incident/${incidentId}/file/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Incident/IncidentFile
     * @name FileIncidentPartialUpdate
     * @summary Updates a File (Incident/IncidentFileController.update)
     * @request PATCH:/incident/{incidentId}/file/{id}
     * @secure
     */
    fileIncidentPartialUpdate: (incidentId: number, id: number, data: any, params: RequestParams = {}) =>
      this.request<IncidentFile, void>({
        path: `/incident/${incidentId}/file/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Incident/IncidentFile
     * @name FileIncidentDelete
     * @summary Deletes a File (Incident/IncidentFileController.destroy)
     * @request DELETE:/incident/{incidentId}/file/{id}
     * @secure
     */
    fileIncidentDelete: (incidentId: number, id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/incident/${incidentId}/file/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description Returns an array of IncidentHistory without any relations.
     *
     * @tags Incident/IncidentHistory
     * @name HistoryIncidentList
     * @summary Returns an array of IncidentHistory (Incident/IncidentHistoryController.index)
     * @request GET:/incident/{incidentId}/history
     * @secure
     */
    historyIncidentList: (incidentId: number, params: RequestParams = {}) =>
      this.request<IncidentHistory[], void>({
        path: `/incident/${incidentId}/history`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a IncidentHistory without any relations.
     *
     * @tags Incident/IncidentHistory
     * @name HistoryIncidentCreate
     * @summary Returns a IncidentHistory (Incident/IncidentHistoryController.store)
     * @request POST:/incident/{incidentId}/history
     * @secure
     */
    historyIncidentCreate: (incidentId: number, data: IncidentHistoryStoreValidator, params: RequestParams = {}) =>
      this.request<IncidentHistory, void>({
        path: `/incident/${incidentId}/history`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a IncidentHistory without any relations.
     *
     * @tags Incident/IncidentHistory
     * @name HistoryIncidentDetail
     * @summary Returns a IncidentHistory (Incident/IncidentHistoryController.show)
     * @request GET:/incident/{incidentId}/history/{id}
     * @secure
     */
    historyIncidentDetail: (incidentId: number, id: number, params: RequestParams = {}) =>
      this.request<IncidentHistory, void>({
        path: `/incident/${incidentId}/history/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Incident/IncidentHistory
     * @name HistoryIncidentPartialUpdate
     * @summary Updates a History (Incident/IncidentHistoryController.update)
     * @request PATCH:/incident/{incidentId}/history/{id}
     * @secure
     */
    historyIncidentPartialUpdate: (incidentId: number, id: number, data: any, params: RequestParams = {}) =>
      this.request<IncidentHistory, void>({
        path: `/incident/${incidentId}/history/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Incident/IncidentHistory
     * @name HistoryIncidentDelete
     * @summary Deletes a History (Incident/IncidentHistoryController.destroy)
     * @request DELETE:/incident/{incidentId}/history/{id}
     * @secure
     */
    historyIncidentDelete: (incidentId: number, id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/incident/${incidentId}/history/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  incidentAction = {
    /**
     * @description Returns an array of IncidentAction without any relations.
     *
     * @tags Incident/IncidentAction
     * @name IncidentActionList
     * @summary Returns an array of IncidentAction (Incident/IncidentActionController.index)
     * @request GET:/incidentAction
     * @secure
     */
    incidentActionList: (params: RequestParams = {}) =>
      this.request<IncidentAction[], void>({
        path: `/incidentAction`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a IncidentAction without any relations.
     *
     * @tags Incident/IncidentAction
     * @name IncidentActionCreate
     * @summary Returns a IncidentAction (Incident/IncidentActionController.store)
     * @request POST:/incidentAction
     * @secure
     */
    incidentActionCreate: (data: any, params: RequestParams = {}) =>
      this.request<IncidentAction, void>({
        path: `/incidentAction`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a IncidentAction without any relations.
     *
     * @tags Incident/IncidentAction
     * @name IncidentActionDetail
     * @summary Returns a IncidentAction (Incident/IncidentActionController.show)
     * @request GET:/incidentAction/{id}
     * @secure
     */
    incidentActionDetail: (id: number, params: RequestParams = {}) =>
      this.request<IncidentAction, void>({
        path: `/incidentAction/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Incident/IncidentAction
     * @name IncidentActionPartialUpdate
     * @summary Updates a IncidentAction (Incident/IncidentActionController.update)
     * @request PATCH:/incidentAction/{id}
     * @secure
     */
    incidentActionPartialUpdate: (id: number, data: any, params: RequestParams = {}) =>
      this.request<IncidentAction, void>({
        path: `/incidentAction/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Incident/IncidentAction
     * @name IncidentActionDelete
     * @summary Deletes a IncidentAction (Incident/IncidentActionController.destroy)
     * @request DELETE:/incidentAction/{id}
     * @secure
     */
    incidentActionDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/incidentAction/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  incidentAssignment = {
    /**
     * @description Returns an array of IncidentAssignment without any relations.
     *
     * @tags Incident/IncidentAssignment
     * @name IncidentAssignmentList
     * @summary Returns an array of IncidentAssignment (Incident/IncidentAssignmentController.index)
     * @request GET:/incidentAssignment
     * @secure
     */
    incidentAssignmentList: (params: RequestParams = {}) =>
      this.request<IncidentAssignment[], void>({
        path: `/incidentAssignment`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a IncidentAssignment without any relations.
     *
     * @tags Incident/IncidentAssignment
     * @name IncidentAssignmentCreate
     * @summary Returns a IncidentAssignment (Incident/IncidentAssignmentController.store)
     * @request POST:/incidentAssignment
     * @secure
     */
    incidentAssignmentCreate: (data: any, params: RequestParams = {}) =>
      this.request<IncidentAssignment, void>({
        path: `/incidentAssignment`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a IncidentAssignment without any relations.
     *
     * @tags Incident/IncidentAssignment
     * @name IncidentAssignmentDetail
     * @summary Returns a IncidentAssignment (Incident/IncidentAssignmentController.show)
     * @request GET:/incidentAssignment/{id}
     * @secure
     */
    incidentAssignmentDetail: (id: number, params: RequestParams = {}) =>
      this.request<IncidentAssignment, void>({
        path: `/incidentAssignment/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Incident/IncidentAssignment
     * @name IncidentAssignmentPartialUpdate
     * @summary Updates a IncidentAssignment (Incident/IncidentAssignmentController.update)
     * @request PATCH:/incidentAssignment/{id}
     * @secure
     */
    incidentAssignmentPartialUpdate: (id: number, data: any, params: RequestParams = {}) =>
      this.request<IncidentAssignment, void>({
        path: `/incidentAssignment/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Incident/IncidentAssignment
     * @name IncidentAssignmentDelete
     * @summary Deletes a IncidentAssignment (Incident/IncidentAssignmentController.destroy)
     * @request DELETE:/incidentAssignment/{id}
     * @secure
     */
    incidentAssignmentDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/incidentAssignment/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  incidentFamily = {
    /**
     * @description Returns an array of IncidentFamily including subFamilies.
     *
     * @tags Incident/IncidentFamily
     * @name IncidentFamilyList
     * @summary Returns an array of IncidentFamily (Incident/IncidentFamilyController.index)
     * @request GET:/incidentFamily
     * @secure
     */
    incidentFamilyList: (params: RequestParams = {}) =>
      this.request<IncidentFamily[], void>({
        path: `/incidentFamily`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a IncidentFamily without any relations.
     *
     * @tags Incident/IncidentFamily
     * @name IncidentFamilyCreate
     * @summary Returns a IncidentFamily (Incident/IncidentFamilyController.store)
     * @request POST:/incidentFamily
     * @secure
     */
    incidentFamilyCreate: (data: any, params: RequestParams = {}) =>
      this.request<IncidentFamily, void>({
        path: `/incidentFamily`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a IncidentFamily including subFamilies.
     *
     * @tags Incident/IncidentFamily
     * @name IncidentFamilyDetail
     * @summary Returns a IncidentFamily (Incident/IncidentFamilyController.show)
     * @request GET:/incidentFamily/{id}
     * @secure
     */
    incidentFamilyDetail: (id: number, params: RequestParams = {}) =>
      this.request<IncidentFamily, void>({
        path: `/incidentFamily/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Incident/IncidentFamily
     * @name IncidentFamilyPartialUpdate
     * @summary Updates a IncidentFamily (Incident/IncidentFamilyController.update)
     * @request PATCH:/incidentFamily/{id}
     * @secure
     */
    incidentFamilyPartialUpdate: (id: number, data: any, params: RequestParams = {}) =>
      this.request<IncidentFamily, void>({
        path: `/incidentFamily/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Incident/IncidentFamily
     * @name IncidentFamilyDelete
     * @summary Deletes a IncidentFamily (Incident/IncidentFamilyController.destroy)
     * @request DELETE:/incidentFamily/{id}
     * @secure
     */
    incidentFamilyDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/incidentFamily/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  incidentPriority = {
    /**
     * @description Returns an array of IncidentPriority without any relations.
     *
     * @tags Incident/IncidentPriority
     * @name IncidentPriorityList
     * @summary Returns an array of IncidentPriority (Incident/IncidentPriorityController.index)
     * @request GET:/incidentPriority
     * @secure
     */
    incidentPriorityList: (params: RequestParams = {}) =>
      this.request<IncidentPriority[], void>({
        path: `/incidentPriority`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a IncidentPriority without any relations.
     *
     * @tags Incident/IncidentPriority
     * @name IncidentPriorityCreate
     * @summary Returns a IncidentPriority (Incident/IncidentPriorityController.store)
     * @request POST:/incidentPriority
     * @secure
     */
    incidentPriorityCreate: (data: any, params: RequestParams = {}) =>
      this.request<IncidentPriority, void>({
        path: `/incidentPriority`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a IncidentPriority without any relations.
     *
     * @tags Incident/IncidentPriority
     * @name IncidentPriorityDetail
     * @summary Returns a IncidentPriority (Incident/IncidentPriorityController.show)
     * @request GET:/incidentPriority/{id}
     * @secure
     */
    incidentPriorityDetail: (id: number, params: RequestParams = {}) =>
      this.request<IncidentPriority, void>({
        path: `/incidentPriority/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Incident/IncidentPriority
     * @name IncidentPriorityPartialUpdate
     * @summary Updates a IncidentPriority (Incident/IncidentPriorityController.update)
     * @request PATCH:/incidentPriority/{id}
     * @secure
     */
    incidentPriorityPartialUpdate: (id: number, data: any, params: RequestParams = {}) =>
      this.request<IncidentPriority, void>({
        path: `/incidentPriority/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Incident/IncidentPriority
     * @name IncidentPriorityDelete
     * @summary Deletes a IncidentPriority (Incident/IncidentPriorityController.destroy)
     * @request DELETE:/incidentPriority/{id}
     * @secure
     */
    incidentPriorityDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/incidentPriority/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  incidentStatus = {
    /**
     * @description Returns an array of IncidentStatus without any relations.
     *
     * @tags Incident/IncidentStatus
     * @name IncidentStatusList
     * @summary Returns an array of IncidentStatus (Incident/IncidentStatusController.index)
     * @request GET:/incidentStatus
     * @secure
     */
    incidentStatusList: (params: RequestParams = {}) =>
      this.request<IncidentStatus[], void>({
        path: `/incidentStatus`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a IncidentStatus without any relations.
     *
     * @tags Incident/IncidentStatus
     * @name IncidentStatusCreate
     * @summary Returns a IncidentStatus (Incident/IncidentStatusController.store)
     * @request POST:/incidentStatus
     * @secure
     */
    incidentStatusCreate: (data: any, params: RequestParams = {}) =>
      this.request<IncidentStatus, void>({
        path: `/incidentStatus`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a IncidentStatus without any relations.
     *
     * @tags Incident/IncidentStatus
     * @name IncidentStatusDetail
     * @summary Returns a IncidentStatus (Incident/IncidentStatusController.show)
     * @request GET:/incidentStatus/{id}
     * @secure
     */
    incidentStatusDetail: (id: string, params: RequestParams = {}) =>
      this.request<IncidentStatus, void>({
        path: `/incidentStatus/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Incident/IncidentStatus
     * @name IncidentStatusPartialUpdate
     * @summary Updates a IncidentStatus (Incident/IncidentStatusController.update)
     * @request PATCH:/incidentStatus/{id}
     * @secure
     */
    incidentStatusPartialUpdate: (id: string, data: any, params: RequestParams = {}) =>
      this.request<IncidentStatus, void>({
        path: `/incidentStatus/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Incident/IncidentStatus
     * @name IncidentStatusDelete
     * @summary Deletes a IncidentStatus (Incident/IncidentStatusController.destroy)
     * @request DELETE:/incidentStatus/{id}
     * @secure
     */
    incidentStatusDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/incidentStatus/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  project = {
    /**
     * @description Returns a ProjectPaginated without any relations.
     *
     * @tags Project
     * @name IndexPaginatedProjectList
     * @summary Returns a ProjectPaginated (ProjectController.indexPaginated)
     * @request GET:/project/indexPaginated
     * @secure
     */
    indexPaginatedProjectList: (query: IndexPaginatedProjectListParams, params: RequestParams = {}) =>
      this.request<ProjectPaginated, void>({
        path: `/project/indexPaginated`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns an array of Project including imputations.
     *
     * @tags Project
     * @name ProjectList
     * @summary Returns an array of Project (ProjectController.index)
     * @request GET:/project
     * @secure
     */
    projectList: (params: RequestParams = {}) =>
      this.request<Project[], void>({
        path: `/project`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a Project without any relations.
     *
     * @tags Project
     * @name ProjectCreate
     * @summary Returns a Project (ProjectController.store)
     * @request POST:/project
     * @secure
     */
    projectCreate: (data: ProjectStoreValidator, params: RequestParams = {}) =>
      this.request<Project, void>({
        path: `/project`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a Project including imputations.
     *
     * @tags Project
     * @name ProjectDetail
     * @summary Returns a Project (ProjectController.show)
     * @request GET:/project/{id}
     * @secure
     */
    projectDetail: (id: string, params: RequestParams = {}) =>
      this.request<Project, void>({
        path: `/project/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Project
     * @name ProjectPartialUpdate
     * @summary Updates a Project (ProjectController.update)
     * @request PATCH:/project/{id}
     * @secure
     */
    projectPartialUpdate: (id: string, data: ProjectUpdateValidator, params: RequestParams = {}) =>
      this.request<Project, void>({
        path: `/project/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Project
     * @name ProjectDelete
     * @summary Deletes a Project (ProjectController.destroy)
     * @request DELETE:/project/{id}
     * @secure
     */
    projectDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/project/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  projectStatus = {
    /**
     * @description Returns an array of ProjectStatus without any relations.
     *
     * @tags ProjectStatus
     * @name ProjectStatusList
     * @summary Returns an array of ProjectStatus (ProjectStatusController.index)
     * @request GET:/projectStatus
     * @secure
     */
    projectStatusList: (params: RequestParams = {}) =>
      this.request<ProjectStatus[], void>({
        path: `/projectStatus`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a ProjectStatus without any relations.
     *
     * @tags ProjectStatus
     * @name ProjectStatusCreate
     * @summary Returns a ProjectStatus (ProjectStatusController.store)
     * @request POST:/projectStatus
     * @secure
     */
    projectStatusCreate: (data: any, params: RequestParams = {}) =>
      this.request<ProjectStatus, void>({
        path: `/projectStatus`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a ProjectStatus without any relations.
     *
     * @tags ProjectStatus
     * @name ProjectStatusDetail
     * @summary Returns a ProjectStatus (ProjectStatusController.show)
     * @request GET:/projectStatus/{id}
     * @secure
     */
    projectStatusDetail: (id: number, params: RequestParams = {}) =>
      this.request<ProjectStatus, void>({
        path: `/projectStatus/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProjectStatus
     * @name ProjectStatusPartialUpdate
     * @summary Updates a ProjectStatus (ProjectStatusController.update)
     * @request PATCH:/projectStatus/{id}
     * @secure
     */
    projectStatusPartialUpdate: (id: number, data: any, params: RequestParams = {}) =>
      this.request<ProjectStatus, void>({
        path: `/projectStatus/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ProjectStatus
     * @name ProjectStatusDelete
     * @summary Deletes a ProjectStatus (ProjectStatusController.destroy)
     * @request DELETE:/projectStatus/{id}
     * @secure
     */
    projectStatusDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/projectStatus/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  software = {
    /**
     * @description Returns an array of Software without any relations.
     *
     * @tags Software
     * @name SoftwareList
     * @summary Returns an array of Software (SoftwareController.index)
     * @request GET:/software
     * @secure
     */
    softwareList: (params: RequestParams = {}) =>
      this.request<Software[], void>({
        path: `/software`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a Software without any relations.
     *
     * @tags Software
     * @name SoftwareCreate
     * @summary Returns a Software (SoftwareController.store)
     * @request POST:/software
     * @secure
     */
    softwareCreate: (data: any, params: RequestParams = {}) =>
      this.request<Software, void>({
        path: `/software`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a Software without any relations.
     *
     * @tags Software
     * @name SoftwareDetail
     * @summary Returns a Software (SoftwareController.show)
     * @request GET:/software/{id}
     * @secure
     */
    softwareDetail: (id: number, params: RequestParams = {}) =>
      this.request<Software, void>({
        path: `/software/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Software
     * @name SoftwarePartialUpdate
     * @summary Updates a Software (SoftwareController.update)
     * @request PATCH:/software/{id}
     * @secure
     */
    softwarePartialUpdate: (id: number, data: any, params: RequestParams = {}) =>
      this.request<Software, void>({
        path: `/software/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Software
     * @name SoftwareDelete
     * @summary Deletes a Software (SoftwareController.destroy)
     * @request DELETE:/software/{id}
     * @secure
     */
    softwareDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/software/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  user = {
    /**
     * @description Returns an array of UserSettings without any relations.
     *
     * @tags UserSettings
     * @name SettingsUserList
     * @summary Returns an array of UserSettings (UserSettingsController.index)
     * @request GET:/user/{userId}/settings
     * @secure
     */
    settingsUserList: (userId: number, params: RequestParams = {}) =>
      this.request<UserSettings[], void>({
        path: `/user/${userId}/settings`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a UserSettings without any relations.
     *
     * @tags UserSettings
     * @name SettingsUserCreate
     * @summary Returns a UserSettings (UserSettingsController.store)
     * @request POST:/user/{userId}/settings
     * @secure
     */
    settingsUserCreate: (userId: number, data: UserSettingsStoreValidator, params: RequestParams = {}) =>
      this.request<UserSettings, void>({
        path: `/user/${userId}/settings`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a UserSettings without any relations.
     *
     * @tags UserSettings
     * @name SettingsUserDetail
     * @summary Returns a UserSettings (UserSettingsController.show)
     * @request GET:/user/{userId}/settings/{id}
     * @secure
     */
    settingsUserDetail: (userId: number, id: number, params: RequestParams = {}) =>
      this.request<UserSettings, void>({
        path: `/user/${userId}/settings/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserSettings
     * @name SettingsUserPartialUpdate
     * @summary Updates a Settings (UserSettingsController.update)
     * @request PATCH:/user/{userId}/settings/{id}
     * @secure
     */
    settingsUserPartialUpdate: (
      userId: number,
      id: number,
      data: UserSettingsUpdateValidator,
      params: RequestParams = {},
    ) =>
      this.request<UserSettings, void>({
        path: `/user/${userId}/settings/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserSettings
     * @name SettingsUserDelete
     * @summary Deletes a Settings (UserSettingsController.destroy)
     * @request DELETE:/user/{userId}/settings/{id}
     * @secure
     */
    settingsUserDelete: (userId: number, id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/user/${userId}/settings/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description Returns a UserPaginated without any relations.
     *
     * @tags User
     * @name IndexPaginatedUserList
     * @summary Returns a UserPaginated (UserController.indexPaginated)
     * @request GET:/user/indexPaginated
     * @secure
     */
    indexPaginatedUserList: (query: IndexPaginatedUserListParams, params: RequestParams = {}) =>
      this.request<UserPaginated, void>({
        path: `/user/indexPaginated`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns an array of User without any relations.
     *
     * @tags User
     * @name DisabledUserList
     * @summary Returns disabled users (UserController.indexDisabled)
     * @request GET:/user/disabled
     * @secure
     */
    disabledUserList: (params: RequestParams = {}) =>
      this.request<User[], void>({
        path: `/user/disabled`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns an array of User including userInfo.
     *
     * @tags User
     * @name AllUserList
     * @summary Returns all users (UserController.indexAll)
     * @request GET:/user/all
     * @secure
     */
    allUserList: (params: RequestParams = {}) =>
      this.request<User[], void>({
        path: `/user/all`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a User including userInfo, settings.
     *
     * @tags User
     * @name ProfileUserList
     * @summary Returns a User (UserController.profile)
     * @request GET:/user/profile
     * @secure
     */
    profileUserList: (params: RequestParams = {}) =>
      this.request<User, void>({
        path: `/user/profile`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UpdatePasswordUserPartialUpdate
     * @summary  (UserController.updatePassword)
     * @request PATCH:/user/updatePassword
     * @secure
     */
    updatePasswordUserPartialUpdate: (data: UserUpdatePasswordValidator, params: RequestParams = {}) =>
      this.request<User, void>({
        path: `/user/updatePassword`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns an array of User including userInfo.
     *
     * @tags User
     * @name UserList
     * @summary Returns an array of User (UserController.index)
     * @request GET:/user
     * @secure
     */
    userList: (params: RequestParams = {}) =>
      this.request<User[], void>({
        path: `/user`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a User without any relations.
     *
     * @tags User
     * @name UserCreate
     * @summary Returns a User (UserController.store)
     * @request POST:/user
     * @secure
     */
    userCreate: (data: UserStoreValidator, params: RequestParams = {}) =>
      this.request<User, void>({
        path: `/user`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a User including userInfo.
     *
     * @tags User
     * @name UserDetail
     * @summary Returns a User (UserController.show)
     * @request GET:/user/{id}
     * @secure
     */
    userDetail: (id: number, params: RequestParams = {}) =>
      this.request<User, void>({
        path: `/user/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserPartialUpdate
     * @summary Updates a User (UserController.update)
     * @request PATCH:/user/{id}
     * @secure
     */
    userPartialUpdate: (id: number, data: UserUpdateValidator, params: RequestParams = {}) =>
      this.request<User, void>({
        path: `/user/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UserDelete
     * @summary Deletes a User (UserController.destroy)
     * @request DELETE:/user/{id}
     * @secure
     */
    userDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/user/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description Returns a User without any relations.
     *
     * @tags User
     * @name NewUserUserCreate
     * @summary Returns a User (UserController.store)
     * @request POST:/user/newUser
     */
    newUserUserCreate: (data: UserStoreValidator, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/user/newUser`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  settings = {
    /**
     * @description Returns an array of UserSettings without any relations.
     *
     * @tags UserSettings
     * @name SettingsList
     * @summary Returns an array of UserSettings (UserSettingsController.index)
     * @request GET:/settings
     * @secure
     */
    settingsList: (params: RequestParams = {}) =>
      this.request<UserSettings[], void>({
        path: `/settings`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a UserSettings without any relations.
     *
     * @tags UserSettings
     * @name SettingsCreate
     * @summary Returns a UserSettings (UserSettingsController.store)
     * @request POST:/settings
     * @secure
     */
    settingsCreate: (data: UserSettingsStoreValidator, params: RequestParams = {}) =>
      this.request<UserSettings, void>({
        path: `/settings`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a UserSettings without any relations.
     *
     * @tags UserSettings
     * @name SettingsDetail
     * @summary Returns a UserSettings (UserSettingsController.show)
     * @request GET:/settings/{id}
     * @secure
     */
    settingsDetail: (id: number, params: RequestParams = {}) =>
      this.request<UserSettings, void>({
        path: `/settings/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserSettings
     * @name SettingsPartialUpdate
     * @summary Updates a Settings (UserSettingsController.update)
     * @request PATCH:/settings/{id}
     * @secure
     */
    settingsPartialUpdate: (id: number, data: UserSettingsUpdateValidator, params: RequestParams = {}) =>
      this.request<UserSettings, void>({
        path: `/settings/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserSettings
     * @name SettingsDelete
     * @summary Deletes a Settings (UserSettingsController.destroy)
     * @request DELETE:/settings/{id}
     * @secure
     */
    settingsDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/settings/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  userInfo = {
    /**
     * @description Returns a UserInfoPaginated without any relations.
     *
     * @tags UserInfo
     * @name IndexPaginatedUserInfoList
     * @summary Returns a UserInfoPaginated (UserInfoController.indexPaginated)
     * @request GET:/userInfo/indexPaginated
     * @secure
     */
    indexPaginatedUserInfoList: (query: IndexPaginatedUserInfoListParams, params: RequestParams = {}) =>
      this.request<UserInfoPaginated, void>({
        path: `/userInfo/indexPaginated`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns an array of UserInfo including user.
     *
     * @tags UserInfo
     * @name AllUserInfoList
     * @summary Returns all users (UserInfoController.indexAll)
     * @request GET:/userInfo/all
     * @secure
     */
    allUserInfoList: (params: RequestParams = {}) =>
      this.request<UserInfo[], void>({
        path: `/userInfo/all`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns an array of UserInfo including user.
     *
     * @tags UserInfo
     * @name UserInfoList
     * @summary Returns an array of UserInfo (UserInfoController.index)
     * @request GET:/userInfo
     * @secure
     */
    userInfoList: (params: RequestParams = {}) =>
      this.request<UserInfo[], void>({
        path: `/userInfo`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a UserInfo without any relations.
     *
     * @tags UserInfo
     * @name UserInfoCreate
     * @summary Returns a UserInfo (UserInfoController.store)
     * @request POST:/userInfo
     * @secure
     */
    userInfoCreate: (data: UserInfoStoreValidator, params: RequestParams = {}) =>
      this.request<UserInfo, void>({
        path: `/userInfo`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a UserInfo including user.
     *
     * @tags UserInfo
     * @name UserInfoDetail
     * @summary Returns a UserInfo (UserInfoController.show)
     * @request GET:/userInfo/{id}
     * @secure
     */
    userInfoDetail: (id: string, params: RequestParams = {}) =>
      this.request<UserInfo, void>({
        path: `/userInfo/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name UserInfoPartialUpdate
     * @summary Updates a UserInfo (UserInfoController.update)
     * @request PATCH:/userInfo/{id}
     * @secure
     */
    userInfoPartialUpdate: (id: string, data: UserInfoUpdateValidator, params: RequestParams = {}) =>
      this.request<UserInfo, void>({
        path: `/userInfo/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserInfo
     * @name UserInfoDelete
     * @summary Deletes a UserInfo (UserInfoController.destroy)
     * @request DELETE:/userInfo/{id}
     * @secure
     */
    userInfoDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/userInfo/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  reviewCategory = {
    /**
     * @description Returns an array of ReviewCategory without any relations.
     *
     * @tags Review/ReviewCategory
     * @name ReviewCategoryList
     * @summary Returns an array of ReviewCategory (Review/ReviewCategoryController.index)
     * @request GET:/reviewCategory
     * @secure
     */
    reviewCategoryList: (params: RequestParams = {}) =>
      this.request<ReviewCategory[], void>({
        path: `/reviewCategory`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a ReviewCategory without any relations.
     *
     * @tags Review/ReviewCategory
     * @name ReviewCategoryCreate
     * @summary Returns a ReviewCategory (Review/ReviewCategoryController.store)
     * @request POST:/reviewCategory
     * @secure
     */
    reviewCategoryCreate: (data: any, params: RequestParams = {}) =>
      this.request<ReviewCategory, void>({
        path: `/reviewCategory`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a ReviewCategory without any relations.
     *
     * @tags Review/ReviewCategory
     * @name ReviewCategoryDetail
     * @summary Returns a ReviewCategory (Review/ReviewCategoryController.show)
     * @request GET:/reviewCategory/{id}
     * @secure
     */
    reviewCategoryDetail: (id: number, params: RequestParams = {}) =>
      this.request<ReviewCategory, void>({
        path: `/reviewCategory/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Review/ReviewCategory
     * @name ReviewCategoryPartialUpdate
     * @summary Updates a ReviewCategory (Review/ReviewCategoryController.update)
     * @request PATCH:/reviewCategory/{id}
     * @secure
     */
    reviewCategoryPartialUpdate: (id: number, data: any, params: RequestParams = {}) =>
      this.request<ReviewCategory, void>({
        path: `/reviewCategory/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Review/ReviewCategory
     * @name ReviewCategoryDelete
     * @summary Deletes a ReviewCategory (Review/ReviewCategoryController.destroy)
     * @request DELETE:/reviewCategory/{id}
     * @secure
     */
    reviewCategoryDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/reviewCategory/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  reviewBoard = {
    /**
     * @description Returns an array of ReviewBoard without any relations.
     *
     * @tags Review/ReviewBoard
     * @name ReviewBoardList
     * @summary Returns an array of ReviewBoard (Review/ReviewBoardController.index)
     * @request GET:/reviewBoard
     * @secure
     */
    reviewBoardList: (params: RequestParams = {}) =>
      this.request<ReviewBoard[], void>({
        path: `/reviewBoard`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a ReviewBoard without any relations.
     *
     * @tags Review/ReviewBoard
     * @name ReviewBoardCreate
     * @summary Returns a ReviewBoard (Review/ReviewBoardController.store)
     * @request POST:/reviewBoard
     * @secure
     */
    reviewBoardCreate: (data: ReviewBoardStoreValidator, params: RequestParams = {}) =>
      this.request<ReviewBoard, void>({
        path: `/reviewBoard`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a ReviewBoard including boardCategories, rates, activityReports, cards.
     *
     * @tags Review/ReviewBoard
     * @name ReviewBoardDetail
     * @summary Returns a ReviewBoard (Review/ReviewBoardController.show)
     * @request GET:/reviewBoard/{id}
     * @secure
     */
    reviewBoardDetail: (id: number, params: RequestParams = {}) =>
      this.request<ReviewBoard, void>({
        path: `/reviewBoard/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Review/ReviewBoard
     * @name ReviewBoardPartialUpdate
     * @summary Updates a ReviewBoard (Review/ReviewBoardController.update)
     * @request PATCH:/reviewBoard/{id}
     * @secure
     */
    reviewBoardPartialUpdate: (id: number, data: ReviewBoardUpdateValidator, params: RequestParams = {}) =>
      this.request<ReviewBoard, void>({
        path: `/reviewBoard/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Review/ReviewBoard
     * @name ReviewBoardDelete
     * @summary Deletes a ReviewBoard (Review/ReviewBoardController.destroy)
     * @request DELETE:/reviewBoard/{id}
     * @secure
     */
    reviewBoardDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/reviewBoard/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Review/ReviewBoard
     * @name UpdateCardOrderReviewBoardPartialUpdate
     * @summary  (Review/ReviewBoardController.updateCardOrder)
     * @request PATCH:/reviewBoard/{id}/updateCardOrder
     * @secure
     */
    updateCardOrderReviewBoardPartialUpdate: (
      id: number,
      data: ReviewBoardUpdateCardOrderValidator,
      params: RequestParams = {},
    ) =>
      this.request<ReviewCard, void>({
        path: `/reviewBoard/${id}/updateCardOrder`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a ReviewBoardEmail without any relations.
     *
     * @tags Review/ReviewBoard
     * @name EmailReviewBoardList
     * @summary Returns a ReviewBoardEmail (Review/ReviewBoardController.email)
     * @request GET:/reviewBoard/{id}/email
     * @secure
     */
    emailReviewBoardList: (id: number, params: RequestParams = {}) =>
      this.request<ReviewBoardEmail, void>({
        path: `/reviewBoard/${id}/email`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns an array of ReviewBoardCategory without any relations.
     *
     * @tags Review/ReviewBoardCategory
     * @name ReviewBoardCategoryReviewBoardList
     * @summary Returns an array of ReviewBoardCategory (Review/ReviewBoardCategoryController.index)
     * @request GET:/reviewBoard/{reviewBoardId}/reviewBoardCategory
     * @secure
     */
    reviewBoardCategoryReviewBoardList: (reviewBoardId: number, params: RequestParams = {}) =>
      this.request<ReviewBoardCategory[], void>({
        path: `/reviewBoard/${reviewBoardId}/reviewBoardCategory`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a ReviewBoardCategory without any relations.
     *
     * @tags Review/ReviewBoardCategory
     * @name ReviewBoardCategoryReviewBoardCreate
     * @summary Returns a ReviewBoardCategory (Review/ReviewBoardCategoryController.store)
     * @request POST:/reviewBoard/{reviewBoardId}/reviewBoardCategory
     * @secure
     */
    reviewBoardCategoryReviewBoardCreate: (
      reviewBoardId: number,
      data: ReviewBoardCategoryStoreValidator,
      params: RequestParams = {},
    ) =>
      this.request<ReviewBoardCategory, void>({
        path: `/reviewBoard/${reviewBoardId}/reviewBoardCategory`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a ReviewBoardCategory without any relations.
     *
     * @tags Review/ReviewBoardCategory
     * @name ReviewBoardCategoryReviewBoardDetail
     * @summary Returns a ReviewBoardCategory (Review/ReviewBoardCategoryController.show)
     * @request GET:/reviewBoard/{reviewBoardId}/reviewBoardCategory/{id}
     * @secure
     */
    reviewBoardCategoryReviewBoardDetail: (reviewBoardId: number, id: number, params: RequestParams = {}) =>
      this.request<ReviewBoardCategory, void>({
        path: `/reviewBoard/${reviewBoardId}/reviewBoardCategory/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Review/ReviewBoardCategory
     * @name ReviewBoardCategoryReviewBoardPartialUpdate
     * @summary Updates a ReviewBoardCategory (Review/ReviewBoardCategoryController.update)
     * @request PATCH:/reviewBoard/{reviewBoardId}/reviewBoardCategory/{id}
     * @secure
     */
    reviewBoardCategoryReviewBoardPartialUpdate: (
      reviewBoardId: number,
      id: number,
      data: ReviewBoardCategoryUpdateValidator,
      params: RequestParams = {},
    ) =>
      this.request<ReviewBoardCategory, void>({
        path: `/reviewBoard/${reviewBoardId}/reviewBoardCategory/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Review/ReviewBoardCategory
     * @name ReviewBoardCategoryReviewBoardDelete
     * @summary Deletes a ReviewBoardCategory (Review/ReviewBoardCategoryController.destroy)
     * @request DELETE:/reviewBoard/{reviewBoardId}/reviewBoardCategory/{id}
     * @secure
     */
    reviewBoardCategoryReviewBoardDelete: (reviewBoardId: number, id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/reviewBoard/${reviewBoardId}/reviewBoardCategory/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description Returns an array of ReviewRate without any relations.
     *
     * @tags Review/ReviewRate
     * @name ReviewRateReviewBoardList
     * @summary Returns an array of ReviewRate (Review/ReviewRateController.index)
     * @request GET:/reviewBoard/{reviewBoardId}/reviewRate
     * @secure
     */
    reviewRateReviewBoardList: (reviewBoardId: number, params: RequestParams = {}) =>
      this.request<ReviewRate[], void>({
        path: `/reviewBoard/${reviewBoardId}/reviewRate`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a ReviewRate without any relations.
     *
     * @tags Review/ReviewRate
     * @name ReviewRateReviewBoardCreate
     * @summary Returns a ReviewRate (Review/ReviewRateController.store)
     * @request POST:/reviewBoard/{reviewBoardId}/reviewRate
     * @secure
     */
    reviewRateReviewBoardCreate: (reviewBoardId: number, data: ReviewRateStoreValidator, params: RequestParams = {}) =>
      this.request<ReviewRate, void>({
        path: `/reviewBoard/${reviewBoardId}/reviewRate`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a ReviewRate without any relations.
     *
     * @tags Review/ReviewRate
     * @name ReviewRateReviewBoardDetail
     * @summary Returns a ReviewRate (Review/ReviewRateController.show)
     * @request GET:/reviewBoard/{reviewBoardId}/reviewRate/{id}
     * @secure
     */
    reviewRateReviewBoardDetail: (reviewBoardId: number, id: number, params: RequestParams = {}) =>
      this.request<ReviewRate, void>({
        path: `/reviewBoard/${reviewBoardId}/reviewRate/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Review/ReviewRate
     * @name ReviewRateReviewBoardPartialUpdate
     * @summary Updates a ReviewRate (Review/ReviewRateController.update)
     * @request PATCH:/reviewBoard/{reviewBoardId}/reviewRate/{id}
     * @secure
     */
    reviewRateReviewBoardPartialUpdate: (
      reviewBoardId: number,
      id: number,
      data: ReviewRateUpdateValidator,
      params: RequestParams = {},
    ) =>
      this.request<ReviewRate, void>({
        path: `/reviewBoard/${reviewBoardId}/reviewRate/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Review/ReviewRate
     * @name ReviewRateReviewBoardDelete
     * @summary Deletes a ReviewRate (Review/ReviewRateController.destroy)
     * @request DELETE:/reviewBoard/{reviewBoardId}/reviewRate/{id}
     * @secure
     */
    reviewRateReviewBoardDelete: (reviewBoardId: number, id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/reviewBoard/${reviewBoardId}/reviewRate/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description Returns an array of ReviewCard without any relations.
     *
     * @tags Review/ReviewCard
     * @name ReviewCardReviewBoardList
     * @summary Returns an array of ReviewCard (Review/ReviewCardController.index)
     * @request GET:/reviewBoard/{reviewBoardId}/reviewCard
     * @secure
     */
    reviewCardReviewBoardList: (reviewBoardId: number, params: RequestParams = {}) =>
      this.request<ReviewCard[], void>({
        path: `/reviewBoard/${reviewBoardId}/reviewCard`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a ReviewCard without any relations.
     *
     * @tags Review/ReviewCard
     * @name ReviewCardReviewBoardCreate
     * @summary Returns a ReviewCard (Review/ReviewCardController.store)
     * @request POST:/reviewBoard/{reviewBoardId}/reviewCard
     * @secure
     */
    reviewCardReviewBoardCreate: (reviewBoardId: number, data: ReviewCardStoreValidator, params: RequestParams = {}) =>
      this.request<ReviewCard, void>({
        path: `/reviewBoard/${reviewBoardId}/reviewCard`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a ReviewCard without any relations.
     *
     * @tags Review/ReviewCard
     * @name ReviewCardReviewBoardDetail
     * @summary Returns a ReviewCard (Review/ReviewCardController.show)
     * @request GET:/reviewBoard/{reviewBoardId}/reviewCard/{id}
     * @secure
     */
    reviewCardReviewBoardDetail: (reviewBoardId: number, id: number, params: RequestParams = {}) =>
      this.request<ReviewCard, void>({
        path: `/reviewBoard/${reviewBoardId}/reviewCard/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Review/ReviewCard
     * @name ReviewCardReviewBoardPartialUpdate
     * @summary Updates a ReviewCard (Review/ReviewCardController.update)
     * @request PATCH:/reviewBoard/{reviewBoardId}/reviewCard/{id}
     * @secure
     */
    reviewCardReviewBoardPartialUpdate: (
      reviewBoardId: number,
      id: number,
      data: ReviewCardUpdateValidator,
      params: RequestParams = {},
    ) =>
      this.request<ReviewCard, void>({
        path: `/reviewBoard/${reviewBoardId}/reviewCard/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Review/ReviewCard
     * @name ReviewCardReviewBoardDelete
     * @summary Deletes a ReviewCard (Review/ReviewCardController.destroy)
     * @request DELETE:/reviewBoard/{reviewBoardId}/reviewCard/{id}
     * @secure
     */
    reviewCardReviewBoardDelete: (reviewBoardId: number, id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/reviewBoard/${reviewBoardId}/reviewCard/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description Returns an array of ReviewCardComment without any relations.
     *
     * @tags Review/ReviewCardComment
     * @name ReviewCardCommentReviewCardCommentList
     * @summary Returns an array of ReviewCardComment (Review/ReviewCardCommentController.index)
     * @request GET:/reviewBoard/{reviewBoardId}/reviewCardComment/{reviewCardId}/reviewCardComment
     * @secure
     */
    reviewCardCommentReviewCardCommentList: (reviewBoardId: number, reviewCardId: number, params: RequestParams = {}) =>
      this.request<ReviewCardComment[], void>({
        path: `/reviewBoard/${reviewBoardId}/reviewCardComment/${reviewCardId}/reviewCardComment`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a ReviewCardComment without any relations.
     *
     * @tags Review/ReviewCardComment
     * @name ReviewCardCommentReviewCardCommentCreate
     * @summary Returns a ReviewCardComment (Review/ReviewCardCommentController.store)
     * @request POST:/reviewBoard/{reviewBoardId}/reviewCardComment/{reviewCardId}/reviewCardComment
     * @secure
     */
    reviewCardCommentReviewCardCommentCreate: (
      reviewBoardId: number,
      reviewCardId: number,
      data: ReviewCardCommentStoreValidator,
      params: RequestParams = {},
    ) =>
      this.request<ReviewCardComment, void>({
        path: `/reviewBoard/${reviewBoardId}/reviewCardComment/${reviewCardId}/reviewCardComment`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a ReviewCardComment without any relations.
     *
     * @tags Review/ReviewCardComment
     * @name ReviewCardCommentReviewCardCommentDetail
     * @summary Returns a ReviewCardComment (Review/ReviewCardCommentController.show)
     * @request GET:/reviewBoard/{reviewBoardId}/reviewCardComment/{reviewCardId}/reviewCardComment/{id}
     * @secure
     */
    reviewCardCommentReviewCardCommentDetail: (
      reviewBoardId: number,
      reviewCardId: number,
      id: number,
      params: RequestParams = {},
    ) =>
      this.request<ReviewCardComment, void>({
        path: `/reviewBoard/${reviewBoardId}/reviewCardComment/${reviewCardId}/reviewCardComment/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Review/ReviewCardComment
     * @name ReviewCardCommentReviewCardCommentPartialUpdate
     * @summary Updates a ReviewCardComment (Review/ReviewCardCommentController.update)
     * @request PATCH:/reviewBoard/{reviewBoardId}/reviewCardComment/{reviewCardId}/reviewCardComment/{id}
     * @secure
     */
    reviewCardCommentReviewCardCommentPartialUpdate: (
      reviewBoardId: number,
      reviewCardId: number,
      id: number,
      data: ReviewCardCommentUpdateValidator,
      params: RequestParams = {},
    ) =>
      this.request<ReviewCardComment, void>({
        path: `/reviewBoard/${reviewBoardId}/reviewCardComment/${reviewCardId}/reviewCardComment/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Review/ReviewCardComment
     * @name ReviewCardCommentReviewCardCommentDelete
     * @summary Deletes a ReviewCardComment (Review/ReviewCardCommentController.destroy)
     * @request DELETE:/reviewBoard/{reviewBoardId}/reviewCardComment/{reviewCardId}/reviewCardComment/{id}
     * @secure
     */
    reviewCardCommentReviewCardCommentDelete: (
      reviewBoardId: number,
      reviewCardId: number,
      id: number,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/reviewBoard/${reviewBoardId}/reviewCardComment/${reviewCardId}/reviewCardComment/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description Returns an array of ReviewCardReaction without any relations.
     *
     * @tags Review/ReviewCardReaction
     * @name ReviewCardReactionReviewCardList
     * @summary Returns an array of ReviewCardReaction (Review/ReviewCardReactionController.index)
     * @request GET:/reviewBoard/{reviewBoardId}/reviewCard/{reviewCardId}/reviewCardReaction
     * @secure
     */
    reviewCardReactionReviewCardList: (reviewBoardId: number, reviewCardId: number, params: RequestParams = {}) =>
      this.request<ReviewCardReaction[], void>({
        path: `/reviewBoard/${reviewBoardId}/reviewCard/${reviewCardId}/reviewCardReaction`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a ReviewCardReaction without any relations.
     *
     * @tags Review/ReviewCardReaction
     * @name ReviewCardReactionReviewCardCreate
     * @summary Returns a ReviewCardReaction (Review/ReviewCardReactionController.store)
     * @request POST:/reviewBoard/{reviewBoardId}/reviewCard/{reviewCardId}/reviewCardReaction
     * @secure
     */
    reviewCardReactionReviewCardCreate: (
      reviewBoardId: number,
      reviewCardId: number,
      data: ReviewReactionStoreValidator,
      params: RequestParams = {},
    ) =>
      this.request<ReviewCardReaction, void>({
        path: `/reviewBoard/${reviewBoardId}/reviewCard/${reviewCardId}/reviewCardReaction`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a ReviewCardReaction without any relations.
     *
     * @tags Review/ReviewCardReaction
     * @name ReviewCardReactionReviewCardDetail
     * @summary Returns a ReviewCardReaction (Review/ReviewCardReactionController.show)
     * @request GET:/reviewBoard/{reviewBoardId}/reviewCard/{reviewCardId}/reviewCardReaction/{id}
     * @secure
     */
    reviewCardReactionReviewCardDetail: (
      reviewBoardId: number,
      reviewCardId: number,
      id: number,
      params: RequestParams = {},
    ) =>
      this.request<ReviewCardReaction, void>({
        path: `/reviewBoard/${reviewBoardId}/reviewCard/${reviewCardId}/reviewCardReaction/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Review/ReviewCardReaction
     * @name ReviewCardReactionReviewCardPartialUpdate
     * @summary Updates a ReviewCardReaction (Review/ReviewCardReactionController.update)
     * @request PATCH:/reviewBoard/{reviewBoardId}/reviewCard/{reviewCardId}/reviewCardReaction/{id}
     * @secure
     */
    reviewCardReactionReviewCardPartialUpdate: (
      reviewBoardId: number,
      reviewCardId: number,
      id: number,
      data: ReviewReactionUpdateValidator,
      params: RequestParams = {},
    ) =>
      this.request<ReviewCardReaction, void>({
        path: `/reviewBoard/${reviewBoardId}/reviewCard/${reviewCardId}/reviewCardReaction/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Review/ReviewCardReaction
     * @name ReviewCardReactionReviewCardDelete
     * @summary Deletes a ReviewCardReaction (Review/ReviewCardReactionController.destroy)
     * @request DELETE:/reviewBoard/{reviewBoardId}/reviewCard/{reviewCardId}/reviewCardReaction/{id}
     * @secure
     */
    reviewCardReactionReviewCardDelete: (
      reviewBoardId: number,
      reviewCardId: number,
      id: number,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/reviewBoard/${reviewBoardId}/reviewCard/${reviewCardId}/reviewCardReaction/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description Returns an array of ReviewCardCommentReaction without any relations.
     *
     * @tags Review/ReviewCardCommentReaction
     * @name ReviewCardCommentReactionReviewCardCommentList
     * @summary Returns an array of ReviewCardCommentReaction (Review/ReviewCardCommentReactionController.index)
     * @request GET:/reviewBoard/{reviewBoardId}/reviewCardComment/{reviewCardCommentId}/reviewCardCommentReaction
     * @secure
     */
    reviewCardCommentReactionReviewCardCommentList: (
      reviewBoardId: number,
      reviewCardCommentId: number,
      params: RequestParams = {},
    ) =>
      this.request<ReviewCardCommentReaction[], void>({
        path: `/reviewBoard/${reviewBoardId}/reviewCardComment/${reviewCardCommentId}/reviewCardCommentReaction`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a ReviewCardCommentReaction without any relations.
     *
     * @tags Review/ReviewCardCommentReaction
     * @name ReviewCardCommentReactionReviewCardCommentCreate
     * @summary Returns a ReviewCardCommentReaction (Review/ReviewCardCommentReactionController.store)
     * @request POST:/reviewBoard/{reviewBoardId}/reviewCardComment/{reviewCardCommentId}/reviewCardCommentReaction
     * @secure
     */
    reviewCardCommentReactionReviewCardCommentCreate: (
      reviewBoardId: number,
      reviewCardCommentId: number,
      data: ReviewReactionStoreValidator,
      params: RequestParams = {},
    ) =>
      this.request<ReviewCardCommentReaction, void>({
        path: `/reviewBoard/${reviewBoardId}/reviewCardComment/${reviewCardCommentId}/reviewCardCommentReaction`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a ReviewCardCommentReaction without any relations.
     *
     * @tags Review/ReviewCardCommentReaction
     * @name ReviewCardCommentReactionReviewCardCommentDetail
     * @summary Returns a ReviewCardCommentReaction (Review/ReviewCardCommentReactionController.show)
     * @request GET:/reviewBoard/{reviewBoardId}/reviewCardComment/{reviewCardCommentId}/reviewCardCommentReaction/{id}
     * @secure
     */
    reviewCardCommentReactionReviewCardCommentDetail: (
      reviewBoardId: number,
      reviewCardCommentId: number,
      id: number,
      params: RequestParams = {},
    ) =>
      this.request<ReviewCardCommentReaction, void>({
        path: `/reviewBoard/${reviewBoardId}/reviewCardComment/${reviewCardCommentId}/reviewCardCommentReaction/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Review/ReviewCardCommentReaction
     * @name ReviewCardCommentReactionReviewCardCommentPartialUpdate
     * @summary Updates a ReviewCardCommentReaction (Review/ReviewCardCommentReactionController.update)
     * @request PATCH:/reviewBoard/{reviewBoardId}/reviewCardComment/{reviewCardCommentId}/reviewCardCommentReaction/{id}
     * @secure
     */
    reviewCardCommentReactionReviewCardCommentPartialUpdate: (
      reviewBoardId: number,
      reviewCardCommentId: number,
      id: number,
      data: ReviewReactionUpdateValidator,
      params: RequestParams = {},
    ) =>
      this.request<ReviewCardCommentReaction, void>({
        path: `/reviewBoard/${reviewBoardId}/reviewCardComment/${reviewCardCommentId}/reviewCardCommentReaction/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Review/ReviewCardCommentReaction
     * @name ReviewCardCommentReactionReviewCardCommentDelete
     * @summary Deletes a ReviewCardCommentReaction (Review/ReviewCardCommentReactionController.destroy)
     * @request DELETE:/reviewBoard/{reviewBoardId}/reviewCardComment/{reviewCardCommentId}/reviewCardCommentReaction/{id}
     * @secure
     */
    reviewCardCommentReactionReviewCardCommentDelete: (
      reviewBoardId: number,
      reviewCardCommentId: number,
      id: number,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/reviewBoard/${reviewBoardId}/reviewCardComment/${reviewCardCommentId}/reviewCardCommentReaction/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description Returns an array of ReviewActivityReport without any relations.
     *
     * @tags Review/ReviewActivityReport
     * @name ReviewActivityReportReviewBoardList
     * @summary Returns an array of ReviewActivityReport (Review/ReviewActivityReportController.index)
     * @request GET:/reviewBoard/{reviewBoardId}/reviewActivityReport
     * @secure
     */
    reviewActivityReportReviewBoardList: (reviewBoardId: number, params: RequestParams = {}) =>
      this.request<ReviewActivityReport[], void>({
        path: `/reviewBoard/${reviewBoardId}/reviewActivityReport`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a ReviewActivityReport without any relations.
     *
     * @tags Review/ReviewActivityReport
     * @name ReviewActivityReportReviewBoardCreate
     * @summary Returns a ReviewActivityReport (Review/ReviewActivityReportController.store)
     * @request POST:/reviewBoard/{reviewBoardId}/reviewActivityReport
     * @secure
     */
    reviewActivityReportReviewBoardCreate: (
      reviewBoardId: number,
      data: ReviewActivityReportStoreValidator,
      params: RequestParams = {},
    ) =>
      this.request<ReviewActivityReport, void>({
        path: `/reviewBoard/${reviewBoardId}/reviewActivityReport`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns a ReviewActivityReport without any relations.
     *
     * @tags Review/ReviewActivityReport
     * @name ReviewActivityReportReviewBoardDetail
     * @summary Returns a ReviewActivityReport (Review/ReviewActivityReportController.show)
     * @request GET:/reviewBoard/{reviewBoardId}/reviewActivityReport/{id}
     * @secure
     */
    reviewActivityReportReviewBoardDetail: (reviewBoardId: number, id: number, params: RequestParams = {}) =>
      this.request<ReviewActivityReport, void>({
        path: `/reviewBoard/${reviewBoardId}/reviewActivityReport/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Review/ReviewActivityReport
     * @name ReviewActivityReportReviewBoardPartialUpdate
     * @summary Updates a ReviewActivityReport (Review/ReviewActivityReportController.update)
     * @request PATCH:/reviewBoard/{reviewBoardId}/reviewActivityReport/{id}
     * @secure
     */
    reviewActivityReportReviewBoardPartialUpdate: (
      reviewBoardId: number,
      id: number,
      data: ReviewActivityReportUpdateValidator,
      params: RequestParams = {},
    ) =>
      this.request<ReviewActivityReport, void>({
        path: `/reviewBoard/${reviewBoardId}/reviewActivityReport/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Review/ReviewActivityReport
     * @name ReviewActivityReportReviewBoardDelete
     * @summary Deletes a ReviewActivityReport (Review/ReviewActivityReportController.destroy)
     * @request DELETE:/reviewBoard/{reviewBoardId}/reviewActivityReport/{id}
     * @secure
     */
    reviewActivityReportReviewBoardDelete: (reviewBoardId: number, id: number, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/reviewBoard/${reviewBoardId}/reviewActivityReport/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Review/ReviewActivityReport
     * @name ValidateReviewActivityReportPartialUpdate
     * @summary  (Review/ReviewActivityReportController.validate)
     * @request PATCH:/reviewBoard/{reviewBoardId}/reviewActivityReport/{id}/validate
     * @secure
     */
    validateReviewActivityReportPartialUpdate: (
      reviewBoardId: number,
      id: number,
      data: ReviewActivityReportUpdateValidValidator,
      params: RequestParams = {},
    ) =>
      this.request<ReviewActivityReport, void>({
        path: `/reviewBoard/${reviewBoardId}/reviewActivityReport/${id}/validate`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Review/ReviewActivityReport
     * @name SendReviewActivityReportCreate
     * @summary  (Review/ReviewActivityReportController.send)
     * @request POST:/reviewBoard/{reviewBoardId}/reviewActivityReport/send
     * @secure
     */
    sendReviewActivityReportCreate: (reviewBoardId: number, data: any, params: RequestParams = {}) =>
      this.request<ReviewActivityReport, void>({
        path: `/reviewBoard/${reviewBoardId}/reviewActivityReport/send`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  logs = {
    /**
     * No description
     *
     * @tags logs
     * @name LogsList
     * @summary  (route.ts)
     * @request GET:/logs
     */
    logsList: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/logs`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
}
