/* Framework imports -------------------------------------------------------- */
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  usePostReviewCardCommentMutation,
  usePatchReviewCardCommentMutation,
  useDeleteReviewCardCommentMutation,
} from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'
import { isAllowed } from 'helpers/isAllowed'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  CircularProgress,
  DialogActions,
} from '@mui/material'
import { Delete } from '@mui/icons-material'
import { TextField } from 'formik-mui'
import { toast } from 'react-toastify'
import CustomIconButton from 'components/IconButtons/CustomIconButton/CustomIconButton'

/* Type imports ------------------------------------------------------------- */
import {
  Field,
  type FormikContextType,
  type FormikHelpers,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type {
  ReviewCardCommentStoreValidator,
  ReviewCardComment,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const reviewCardCommentSchema = Yup.object().shape<Shape<ReviewCardCommentStoreValidator>>({
  message: Yup.string().required('Le message est obligatoire'),
}).required()

type ReviewCardCommentRequest = FormikContextType<ReviewCardCommentStoreValidator>

/* Styled components -------------------------------------------------------- */
const DialogActionContainer = styled(DialogActions)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px 0px;
  padding: 0px;
`

const FormButton = styled(Button)`
  min-width: 150px;
  height: 100%;

  @media ${(props) => props.theme.media.mobile.main} {
    min-width: 110px;
  }
`

const Container = styled.div`
  width: 100%;
`

/* Component declaration ---------------------------------------------------- */
interface ReviewsCardModalCommentAddEditProps {
  handleClose: () => void;
  reviewCardComment?: ReviewCardComment;
  reviewBoardId: number;
  reviewCardId: number;
}

const ReviewsCardModalCommentAddEdit: React.FC<ReviewsCardModalCommentAddEditProps> = ({
  reviewCardComment,
  reviewBoardId,
  reviewCardId,
  handleClose,
}) => {

  const [
    submitNewReviewCardComment,
  ] = usePostReviewCardCommentMutation()
  const [
    submitEditReviewCardComment,
  ] = usePatchReviewCardCommentMutation()
  const [
    submitDeleteReviewCardComment,
  ] = useDeleteReviewCardCommentMutation()

  const onSubmit = (values: ReviewCardCommentStoreValidator, { setSubmitting, resetForm }: FormikHelpers<ReviewCardCommentStoreValidator>) => {
    if (reviewCardComment) {
      submitEditReviewCardComment({ id: reviewCardComment.id, data: values, reviewBoardId, reviewCardId })
        .then((response) => {
          if (!isApiError(response)) {
            resetForm()
            handleClose()
          } else {
            toast.error('Une erreur est survenue.')
            setSubmitting(false)
          }
        }).catch(console.error)
    } else {
      submitNewReviewCardComment({ reviewBoardId, data: values, reviewCardId })
        .then((response) => {
          if (!isApiError(response)) {
            resetForm()
            handleClose()
          } else {
            toast.error('Une erreur est survenue.')
            setSubmitting(false)
          }
        }).catch(console.error)
    }
  }

  const formikForm: ReviewCardCommentRequest = useForm<ReviewCardCommentStoreValidator>(
    {
      initialValues: {
        message: '',
      },
      onSubmit: onSubmit,
      validationSchema: reviewCardCommentSchema,
    },
  )

  useEffect(() => {
    if (reviewCardComment) {
      formikForm.setValues(reviewCardComment)
    }
  }, [ reviewCardComment ])

  const onDeleteCard = async () => {
    if (!reviewCardComment) return
    await submitDeleteReviewCardComment({ id: reviewCardComment.id, reviewBoardId, reviewCardId })
  }

  return (
    <Container>
      <Form form={formikForm}>
        <Field
          component={TextField}
          placeholder="Commentaire"
          name="message"
          size="small"
          value={formikForm.values.message || ''}
          multiline
          rows={3}
        />
        <DialogActionContainer>
          {
            reviewCardComment && isAllowed({ userId: reviewCardComment.userId }) &&
              <CustomIconButton
                Icon={Delete}
                variant="contained"
                color="error"
                onClick={onDeleteCard}
                label="Supprimer le commentaire"
              />
          }
          <FormButton
            variant="outlined"
            onClick={handleClose}
          >
            Annuler
          </FormButton>
          <FormButton
            variant="contained"
            type="submit"
            disabled={formikForm.isSubmitting}
          >
            {formikForm.isSubmitting ? <CircularProgress size={24} /> : 'Enregistrer'}
          </FormButton>
        </DialogActionContainer>
      </Form>
    </Container>
  )
}

export default ReviewsCardModalCommentAddEdit
